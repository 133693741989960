import { Stage } from '../Stage'
import { Object3D } from 'three'
import { IntroText } from './IntroText'
import IntroPanel from './IntroPanel'
import { InstancedIntroText } from './InstancedIntroText'
import Grid from './Grid'
import animation from './animation.json'
import { Animation } from './Animation'
import { ASPECT_POSTER, COLORS } from '../../../const'
import { Tween } from '@tweenjs/tween.js'
import { App, Controller } from '../App'
import { Lang } from '../../lib/primsic'

const NUM_X = 15
const NUM_Y = 33

export class IntroController implements Controller {
	private readonly animation: Animation
	private readonly object = new Object3D()

	private readonly grid: Grid
	private readonly panel1: IntroPanel
	private readonly panel2: IntroPanel
	private readonly text1: IntroText
	private readonly text3: IntroText
	private readonly text2: IntroText
	private readonly text4: IntroText
	private readonly text5: InstancedIntroText
	private readonly text6: InstancedIntroText
	private readonly text7: IntroText

	private ratio = 0
	private maxScroll = 14 // length of scroll in viewport heights
	private enabled = false
	private needsUpdate = false
	private elements: (IntroText | InstancedIntroText | Grid | IntroPanel)[]
	private tween?: Tween<{ opacity: number }>
	private isLandscape: boolean | null = null
	private setVisible = false
	private lang: Lang | null = null

	constructor(private readonly node: HTMLElement, private readonly app: App, private readonly stage: Stage) {
		const texts = JSON.parse(decodeURI(node.dataset.texts || '[]'))

		const data = structuredClone(animation)

		// map dimensions to 0-1
		const { width, height } = data
		data.layers.forEach((layer) => {
			layer.groups[0].shapes[0].size.forEach((frame) => (frame.v = [frame.v[0] / width, frame.v[1] / height]))
			layer.transform.positionX.forEach((frame) => (frame.v = frame.v / width))
			layer.transform.positionY.forEach((frame) => (frame.v = frame.v / height))
		})

		this.animation = new Animation({ data })

		this.grid = new Grid(stage, this.animation.getLayerByName('grid_1'), this.object, NUM_X, NUM_Y)
		const translate = this.grid.getTranslate()

		this.panel1 = new IntroPanel(stage, this.animation.getLayerByName('panel_1'), this.object, COLORS.black)
		this.panel2 = new IntroPanel(stage, this.animation.getLayerByName('panel_2'), this.object, COLORS.grey05, true)

		this.text1 = new IntroText(stage, this.animation.getLayerByName('text_1'), this.object, texts[0])
		this.text2 = new IntroText(stage, this.animation.getLayerByName('text_2'), this.object, texts[1])

		this.text3 = new IntroText(
			stage,
			this.animation.getLayerByName('text_3'),
			this.object,
			texts[2],
			'center',
			'center'
		)

		this.text4 = new IntroText(
			stage,
			this.animation.getLayerByName('text_4'),
			this.object,
			texts[3],
			'center',
			'center'
		)

		this.text5 = new InstancedIntroText(
			stage,
			this.animation.getLayerByName('text_5'),
			this.object,
			texts[4],
			NUM_X,
			NUM_Y,
			translate
		)

		this.text6 = new InstancedIntroText(
			stage,
			this.animation.getLayerByName('text_6'),
			this.object,
			texts[5],
			NUM_X,
			NUM_Y,
			translate
		)

		this.text7 = new IntroText(
			stage,
			this.animation.getLayerByName('text_7'),
			this.object,
			texts[6],
			'center',
			'center',
			COLORS.black,
			true
		)

		this.elements = [
			this.text1,
			this.text2,
			this.text3,
			this.text4,
			this.text5,
			this.text6,
			this.text7,
			this.grid,
			this.panel1,
			this.panel2
		]

		this.object.visible = false
		this.stage.scene.add(this.object)
	}

	async load() {
		await Promise.all([
			this.text1.init(),
			this.text2.init(),
			this.text3.init(),
			this.text4.init(),
			this.text5.init(),
			this.text6.init(),
			this.text7.init()
		])
		this.resize()
	}

	async show() {
		this.ratio = 0
		this.setVisible = true
		this.enabled = true
	}

	async hide() {
		this.enabled = false

		return new Promise<void>((resolve) => {
			this.tween = new Tween({ opacity: 1 }, this.stage.group)
				.to({ opacity: 0 }, 500)
				.onUpdate(({ opacity }) => this.elements.forEach((element) => element.setOpacity(opacity)))
				.onComplete(() => {
					this.object.visible = false
					resolve()
				})
				.start()
		})
	}

	resize() {
		const { aspect, landscape } = this.stage
		const { lang } = this.app
		const ratio = ASPECT_POSTER * aspect

		//set zero point to top of screen
		this.object.position.y = 0.5 / aspect

		this.elements.forEach((element) => element.resize())

		this.needsUpdate = true

		const gridX = landscape ? 0.8 / ratio : 0.8
		const gridY = landscape ? 0.8 : 0.8 * ratio

		const textX = gridX * 0.85
		const textY = gridY * 0.85

		if (this.text1.layer) {
			this.text1.layer.sizeProperty.frames[1].v = [textX, textY]
			if (this.text1.layer.transform.positionYProperty) {
				this.text1.layer.transform.positionYProperty.frames[3].v = (1 - gridY * 0.9) * 0.5
			}
		}

		if (this.text2.layer) {
			this.text2.layer.sizeProperty.frames[1].v = [textX, textY]
		}

		if (this.grid.layer) {
			this.grid.layer.sizeProperty.frames[1].v = [gridX, gridY]
			this.grid.layer.sizeProperty.frames[2].v = [gridX, gridY]
			this.grid.layer.sizeProperty.frames[3].v = [landscape ? 0.8 : 0.9, landscape ? 0.3 : 0.15]
		}

		if (this.isLandscape === null || this.isLandscape !== landscape || this.lang === null || this.lang !== lang) {
			this.isLandscape = landscape
			this.lang = lang

			this.text1.setBaseScale(landscape ? 1 : 2.25)
			this.text2.setBaseScale(landscape ? 1 : 2.25)
			this.text7.setBaseScale(landscape ? 1 : 2)

			const baseScale = landscape ? 1 : 1.5
			this.text3.setBaseScale(baseScale)
			this.text5.setBaseScale(baseScale)
			this.text6.setBaseScale(baseScale)

			this.text4.setBaseScale(landscape ? 1 : lang === Lang.fr ? 1.25 : 1.5)
		}
	}

	dispose() {
		this.stage.scene.remove(this.object)
		this.text1.dispose()
		this.text2.dispose()
		this.text3.dispose()
		this.text4.dispose()
		this.text5.dispose()
		this.text6.dispose()
		this.text7.dispose()
	}

	update(time: number) {
		if (!this.enabled && !this.needsUpdate) return
		this.needsUpdate = false

		this.ratio = this.app.scrollY / this.stage.height
		const step = this.ratio / this.maxScroll

		this.animation.update(step)
		const animationTime = this.animation.time

		// dont use map alpha if panel is still opaque
		const panelOpacity = this.panel1.getOpacity()
		this.text1.setMapOpacity(1 - panelOpacity)
		this.text2.setMapOpacity(1 - panelOpacity)

		const map = this.stage.fluidMap

		this.grid.update(animationTime, map)
		this.panel1.update(animationTime, map)
		this.panel2.update(animationTime, map)
		this.text1.update(animationTime, map)
		this.text3.update(animationTime, map)
		this.text2.update(animationTime, map)
		this.text4.update(animationTime, map)
		this.text5.update(animationTime, map)
		this.text6.update(animationTime, map)
		this.text7.update(animationTime, map)

		//attach text2 to bottom of text 1
		this.text2.setPositionY(this.text1.getBottom())

		if (this.setVisible) {
			this.setVisible = false
			this.object.visible = true
		}
	}
}
