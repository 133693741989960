import styles from './Panel.module.css'
import { Lang } from '../../lib/primsic'
import { Text } from '../text/Text'
import { Cards } from '../cards/Cards'
import { Gallery } from '../gallery/Gallery'
import { Button } from '../button/Button'
import { Products } from '../products/Products'
import { Title } from '../title/Title'
import { Linklist } from '../linkList/Linklist'
import { Lead } from '../lead/Lead'
import { Table } from '../table/Table'
import { Machines } from '../machines/Machines'
import { LargeText } from '../LargeText'
import { Columns } from '../Columns'
import clsx from 'clsx'
import { CustomSlice } from '../../utils/prepareSlices'
import { Anchor } from '../anchor/Anchor'

type Props = {
	slice_type: 'panel'
	slices: CustomSlice[]
	lang: Lang
	slide: boolean
	closeButton: boolean
}

export function Panel({ slices, lang, slide, closeButton }: Props): string {
	return /* HTML */ `<div class="${clsx(styles.Main, slide && styles.Slide)}">
		<div class="${styles.Inner}">
			${closeButton
				? /* HTML */ `
						<a class="${styles.Close}" href="/${lang}/">
							<svg class="${styles.Icon}" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
								<circle class="${styles.Bg}" cx="30" cy="30" r="30" />
								<circle class="${styles.Circle}" cx="30" cy="30" r="29" />
								<path class="${styles.X}" d="M24.2861 24.2856L28.4761 28.4761" />
								<path class="${styles.X}" d="M31.5244 31.5234L35.7144 35.7139" />
								<path class="${styles.X}" d="M35.7148 24.2856L31.5249 28.4761" />
								<path class="${styles.X}" d="M28.4785 31.5234L24.2885 35.7139" />
							</svg>
						</a>
				  `
				: ''}
			${slices
				.map((slice) => {
					switch (slice.slice_type) {
						case 'title':
							return Title(slice)
						case 'linklist':
							return Linklist(slice)
						case 'text':
							return Text(slice)
						case 'lead':
							return Lead(slice)
						case 'cards':
							return Cards(slice)
						case 'gallery':
							return Gallery(slice)
						case 'button':
							return Button(slice)
						case 'products':
							return Products(slice)
						case 'table':
							return Table(slice)
						case 'machines':
							return Machines(slice)
						case 'large_text':
							return LargeText(slice)
						case 'columns':
							return Columns(slice)
						case 'anchor':
							return Anchor(slice)
					}
				})
				.join('')}
		</div>
	</div>`
}
