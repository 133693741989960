import styles from './ResponsiveImage.module.css'
import { Controller } from '../App'

export class ResponsiveImageController implements Controller {
	private ready = false

	constructor(private readonly node: HTMLElement) {
		this.onLoad = this.onLoad.bind(this)
		this.node.addEventListener('load', this.onLoad)
		this.resize()
	}

	resize(): void {
		const { width } = this.node.getBoundingClientRect()
		if (width > 0) {
			this.node.setAttribute('size', `${width}px`)
			if (!this.ready) {
				this.ready = true
				this.node.setAttribute('srcset', this.node.dataset?.srcset || '')
				this.node.removeAttribute('data-srcset')
			}
		}
	}

	onLoad() {
		this.node.removeEventListener('load', this.onLoad)
		this.node.classList.add(styles.IsLoaded)
	}

	dispose() {
		this.node.removeEventListener('load', this.onLoad)
	}
}
