import styles from './NavigationGrid.module.css'
import buttonStyles from './Button.module.css'
import gridStyles from '../grid/Grid.module.css'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import { NavigationController } from './NavigationController'
import { getDateString } from '../../utils/getDateString'
import { ButtonController } from './ButtonController'
import { GridController } from '../grid/GridController'

export class NavigationGridController {
	private readonly items: HTMLElement[]
	private readonly group = new Group()
	private readonly loading: ButtonController
	private readonly date: ButtonController
	private langButton: ButtonController
	private menuButton: ButtonController
	private grid: GridController

	constructor(
		private readonly node: HTMLElement,
		private readonly navigation: NavigationController,
		animate = false
	) {
		this.onMenuClick = this.onMenuClick.bind(this)
		this.onLangCLick = this.onLangCLick.bind(this)
		this.grid = new GridController(this.node.querySelector(`.${gridStyles.Main}`) as HTMLElement)

		this.items = [node.querySelector(`.${styles.Logo}`) as HTMLElement]

		this.menuButton = new ButtonController(
			node.querySelector(`.${styles.MenuButton} .${buttonStyles.Main}`) as HTMLElement,
			navigation,
			this.onMenuClick
		)
		this.langButton = new ButtonController(
			node.querySelector(`.${styles.LangButton} .${buttonStyles.Main}`) as HTMLElement,
			navigation,
			this.onLangCLick
		)

		this.loading = new ButtonController(
			node.querySelector(`.${styles.Loading} .${buttonStyles.Main}`) as HTMLElement,
			navigation
		)

		this.date = new ButtonController(
			node.querySelector(`.${styles.Date} .${buttonStyles.Main}`) as HTMLElement,
			navigation,
			undefined,
			getDateString()
		)

		if (!animate) {
			this.node.classList.remove(styles.Centered)
			this.loading.hide(0, false, true)
		}

		this.node.style.setProperty('opacity', '1')
	}

	onMenuClick() {
		this.navigation.openMenu('main')
		this.menuButton.hide()
		this.langButton.hide()
	}

	onMenuClose() {
		this.menuButton.show()
		this.langButton.show()
	}

	onLangCLick() {
		this.navigation.openMenu('lang')
		this.menuButton.hide()
		this.langButton.hide()
	}

	async show(animate = false) {
		this.group.removeAll()

		if (animate) {
			await this.loading.hide(0, true)
			await this.animateGrid()
		}

		this.date.show()
		this.menuButton.show()
		this.langButton.show()
	}

	async animateGrid() {
		return new Promise<void>((resolve) => {
			this.grid.setStartPositions()
			const startPositions = this.items.map((icon) => icon.getBoundingClientRect())
			this.node.classList.remove(styles.Centered)
			const endPositions = this.items.map((icon) => icon.getBoundingClientRect())
			this.grid.animate()

			this.items.forEach((node, index) => {
				const x = startPositions[index].x - endPositions[index].x
				const y = startPositions[index].y - endPositions[index].y

				node.style.setProperty('transform', `translate(${x}px,${y}px)`)

				new Tween({ x, y }, this.group)
					.to({ x: 0, y: 0 }, 3000)
					.easing(Easing.Exponential.InOut)
					.onUpdate(({ x, y }) => node.style.setProperty('transform', `translate(${x}px,${y}px)`))
					.onComplete(() => {
						node.style.removeProperty('transform')
						resolve()
					})
					.start()

				new Tween({ flicker: 1 + Math.random() }, this.group)
					.to({ flicker: 0 }, 1000)
					.delay(1000)
					.easing(Easing.Sinusoidal.In)
					.onUpdate(({ flicker }) => {
						const opacity = 1 - (1 + Math.sin(flicker * 1000)) * 0.5
						node.style.setProperty('opacity', `${opacity}`)
					})
					.onComplete(() => node.style.removeProperty('opacity'))
					.start()
			})
		})
	}

	hide() {
		this.date.hide()
		this.menuButton.hide()
		this.langButton.hide()
	}

	dispose(): void {
		this.group.removeAll()
		this.menuButton.dispose()
		this.langButton.dispose()
		this.loading.dispose()
		this.date.dispose()
	}

	update(time: number) {
		this.group.update(time)
		this.grid.update(time)
	}
}
