import { Easing, Tween } from '@tweenjs/tween.js'
import styles from './Button.module.css'
import { NavigationController } from './NavigationController'

export class ButtonController {
	private inner: HTMLElement
	private tween?: Tween<{ y?: number; opacity?: number }>

	constructor(
		private readonly node: HTMLElement,
		private readonly navigation: NavigationController,
		private readonly onClick?: () => void,
		text?: string
	) {
		this.inner = this.node.querySelector(`.${styles.Inner}`) as HTMLElement

		if (text) {
			this.inner.textContent = text
		}

		if (this.onClick) {
			this.node.addEventListener('click', this.onClick)
		}
	}

	show(delay = 0) {
		this.tween?.stop()
		this.node.classList.remove(styles.Visible)

		this.tween = new Tween({ y: -100 }, this.navigation.group)
			.to({ y: 0 }, 500)
			.delay(delay)
			.easing(Easing.Exponential.Out)
			.onStart(() => {
				this.node.classList.add(styles.Visible)
			})
			.onUpdate(({ y }) => this.inner.style.setProperty('transform', `translateY(${y}%)`))
			.start()
	}

	async hide(delay = 0, slide = false, immediate = false) {
		this.tween?.stop()

		if (immediate) {
			this.node.classList.remove(styles.Visible)
			return
		}

		if (slide) {
			return new Promise<void>((resolve) => {
				this.tween = new Tween({ y: 0 }, this.navigation.group)
					.to({ y: 100 }, 500)
					.delay(delay)
					.easing(Easing.Exponential.In)
					.onUpdate(({ y }) => this.inner.style.setProperty('transform', `translateY(${y}%)`))
					.onComplete(() => {
						this.node.classList.remove(styles.Visible)
						resolve()
					})
					.start()
			})
		}

		return new Promise<void>((resolve) => {
			this.tween = new Tween({ opacity: 1 }, this.navigation.group)
				.to({ opacity: 0 }, 250)
				.delay(delay)
				.onUpdate(({ opacity }) => this.inner.style.setProperty('opacity', `${opacity}`))
				.onComplete(() => {
					this.inner.style.setProperty('opacity', '1')
					this.node.classList.remove(styles.Visible)
					resolve()
				})
				.start()
		})
	}

	setText(text: string) {
		this.inner.textContent = text
	}

	dispose() {
		if (this.onClick) {
			this.node.removeEventListener('click', this.onClick)
		}
	}
}
