import styles from './Table.module.css'
import nodeStyles from '../node/Node.module.css'
import typoStyles from '../Typography.module.css'
import { PageDocumentDataBodyTableSlice } from '../../@types/types.generated'
import clsx from 'clsx'
import { asHTML, asLink, Element, HTMLFunctionSerializer, isFilled } from '@prismicio/helpers'
import { FilledLinkToWebField, LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import linkResolver from '../../lib/linkResolver'
import { Arrow } from '../Arrow'

const SmallArrow = /* HTML */ `
	<svg class="${styles.SmallArrow}" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 2.28457L1.9582 0L8 5.17868L14.0418 0L16 2.28457L8 9.14171L0 2.28457Z" />
	</svg>
`

const tableSerializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined,
	children?: string
): string | null | undefined => {
	if (type === Element.label) {
		const label = 'data' in node && 'label' in node.data ? node.data.label : ''
		return `<span class="${clsx(label === 'small' && styles.Small)}">${children}</span>`
	}

	return null
}

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.hyperlink) {
		const { data } = node as RTLinkNode

		switch (data.link_type) {
			case LinkType.Web: {
				const { url, target } = data as FilledLinkToWebField
				return /* HTML */ `<a
					href="${url}"
					${target ? `target="${target}"` : ''}
					rel="noopener noreferrer"
					class="${styles.Link}"
					><span>${text}</span>${Arrow()}</a
				>`
			}

			case LinkType.Document: {
				return /* HTML */ `<a href="${asLink(data, linkResolver)}" class="${styles.Link}"
					><span>${text}</span>${Arrow()}</a
				>`
			}

			case LinkType.Media: {
				const { url } = data
				return /* HTML */ `<a href="${url}" class="${styles.Link}"><span>${text}</span>${Arrow()}</a>`
			}
		}
	}

	return null
}

export const Table = (props: PageDocumentDataBodyTableSlice): string => {
	const {
		primary: { title, list, sidebar_title, sidebar, caption, background },
		items
	} = props

	return /* HTML */ `<div
		class="${clsx(styles.Main, background === 'white' && styles.White, nodeStyles.Main)}"
		data-visible="${styles.Visible}"
	>
		<div class="${styles.Wrapper}">
			<div class="${styles.Inner}">
				<div class="${styles.TitleWrapper}">
					<button class="${clsx(typoStyles.Title, styles.Title)}">
						<span style="--delay:250ms">${title}${SmallArrow}</span>
					</button>
					<div class="${styles.Line}" style="--delay:350ms"></div>
				</div>
				<div class="${styles.Content}">
					${isFilled.richText(list)
						? `<div class="${styles.List}">${asHTML(list, linkResolver, serializer)}</div>`
						: ''}
					${items.length > 0
						? /* HTML */ `<div class="${styles.Table}">
								${items.map((item) => asHTML(item.table_row, linkResolver, tableSerializer)).join('')}
						  </div>`
						: ''}
					${caption ? `<div class="${styles.Caption}">${caption}</div>` : ''}
				</div>
			</div>
			<div class="${styles.Sidebar}">
				<div class="${styles.TitleWrapper}">
					<button class="${clsx(typoStyles.Title, styles.Title)}">
						<span style="--delay:350ms">${sidebar_title}${SmallArrow}</span>
					</button>
					<div class="${styles.Line}" style="--delay:450ms"></div>
				</div>
				<div class="${styles.Content}">
					<div class="${styles.SidebarList}">${asHTML(sidebar, linkResolver, serializer)}</div>
				</div>
			</div>
		</div>
	</div>`
}
