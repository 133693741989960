import styles from './Lead.module.css'
import nodeStyles from '../node/Node.module.css'
import typoStyles from '../Typography.module.css'
import { PageDocumentDataBodyLeadSlice } from '../../@types/types.generated'
import { asHTML } from '@prismicio/helpers'
import clsx from 'clsx'

export const Lead = (props: PageDocumentDataBodyLeadSlice): string => {
	const {
		primary: { text }
	} = props
	return /* HTML */ `<div class="${clsx(styles.Main, nodeStyles.Main)}" data-visible="${styles.Visible}">
		${text ? `<div class="${clsx(typoStyles.Text, styles.Text)}">${asHTML(text)}</div>` : ''}
	</div>`
}
