import { Animation } from '@kilokilo/ae2canvas'
import { App } from '../App'
import styles from './Cards.module.css'
import { CardsController } from './CardsController'
import { CardAnimator } from './CardAnimator'
import { delay } from '../../utils/delay'
import { Tween } from '@tweenjs/tween.js'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export class Card {
	private animation?: Animation
	private isActive = false
	private canvas?: HTMLCanvasElement
	private tween?: Tween<{ opacity: number }>
	private animators: CardAnimator[]

	constructor(
		private readonly node: HTMLElement,
		private readonly index: number,
		private readonly onOver: (index: number) => void,
		private readonly onOut: () => void,
		private readonly controller: CardsController,
		private readonly app: App
	) {
		this.onIntersect = this.onIntersect.bind(this)
		this.onMouseOver = this.onMouseOver.bind(this)
		this.onMouseOut = this.onMouseOut.bind(this)
		this.animators = Array.from(this.node.querySelectorAll(`.${styles.Animator}`) as NodeListOf<HTMLElement>).map(
			(node, index) => new CardAnimator(node, index, controller)
		)

		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	async init() {
		this.canvas = this.node.querySelector(`.${styles.Canvas}`) as HTMLCanvasElement
		let id = this.node.dataset.id
		if (id !== 'none') {
			if (id === 'beratung' && isSafari) {
				id = 'beratung-safari'
			}

			const file = await fetch(`/${id}.json`)
			const data = await file.json()

			this.animation = new Animation({ canvas: this.canvas, data })
		}

		const hasPointer = window.matchMedia('(pointer:fine)').matches

		if (hasPointer) {
			this.node.addEventListener('mouseenter', this.onMouseOver)
			this.node.addEventListener('mouseleave', this.onMouseOut)
		}

		this.resize()
	}

	async setActive() {
		if (this.isActive) {
			return
		}

		const { isDesktop } = this.app
		this.isActive = true

		if (isDesktop) {
			this.animators.forEach((animator) => animator.setActive())
		}

		this.node.classList.add(styles.Active)

		await delay(500)
		this.canvas?.style.setProperty('opacity', '1')
		this.animation?.gotoAndPlay(0)
	}

	async show(showDelay = 0) {
		const { isDesktop } = this.app
		if (isDesktop) {
			await delay(showDelay)
			this.animators.forEach((animator) => animator.show())
		}
	}

	hide() {
		const { isDesktop } = this.app
		if (isDesktop) {
			this.animators.forEach((animator) => animator.hide())
		}
	}

	unsetActive() {
		if (!this.isActive) {
			return
		}

		const { isDesktop } = this.app
		this.isActive = false

		this.node.classList.remove(styles.Active)

		if (isDesktop) {
			this.animators.forEach((animator) => animator.unsetActive())
		}

		this.animation?.play()
		this.tween?.stop()
		this.tween = new Tween({ opacity: 1 }, this.controller.group)
			.to({ opacity: 0 }, 250)
			.delay(500)
			.onUpdate(({ opacity }) => this.canvas?.style.setProperty('opacity', `${opacity}`))
			.start()
	}

	onMouseOver() {
		this.onOver(this.index)
	}

	onMouseOut() {
		this.onOut()
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
			}
		})
	}

	getWidth() {
		return this.node.getBoundingClientRect().width
	}

	resize(): void {
		this.animation?.resize()
	}

	dispose() {
		this.node.removeEventListener('mouseenter', this.onMouseOver)
		this.node.removeEventListener('mouseleave', this.onMouseOut)
		this.app.removeListener('intersect', this.onIntersect)
		this.app.intersectionObserver.unobserve(this.node)
	}
}
