import { SDFGeometry } from '../sdf/SDFGeometry'
import { Color, DoubleSide, Group, Mesh, Object3D, ShaderMaterial, Texture, Vector2 } from 'three'
import vertexShader from './msdf.vert'
import fragmentShader from './msdf.frag'
import { Stage } from '../Stage'
import BaseLayer from './BaseLayer'
import { COLORS, FONT_SCALE, SPACE_WIDTH } from '../../../const'
import { getTextGeometry } from '../textGeometry/getTextGeometry'

const LINE_HEIGHT = 1 / 12

export class IntroText {
	private readonly object = new Object3D()
	private readonly inner = new Group()

	private readonly material: ShaderMaterial
	private readonly sizes: number[][] = []
	private readonly lines: string[][]

	private meshes: Mesh<SDFGeometry, ShaderMaterial>[][] = []
	private fontSize = 1
	private width = 0
	private height = 0
	private y = 0
	private baseScale = 1
	private offsetY = 0
	private needsUpdate = true
	private initialized = false

	constructor(
		private readonly stage: Stage,
		public readonly layer: BaseLayer | null,
		parent: Object3D,
		text: string,
		private vAlign: 'top' | 'center' = 'top',
		private hAlign: 'left' | 'center' = 'left',
		color = COLORS.grey01,
		useDiffuse = false
	) {
		this.lines = text.split('\n').map((line) => line.split(' '))

		this.material = new ShaderMaterial({
			extensions: {
				derivatives: true
			},
			vertexShader,
			fragmentShader,
			side: DoubleSide,
			transparent: true,
			uniforms: {
				useDiffuse: { value: useDiffuse ? 1 : 0 },
				color: { value: new Color(color) },
				resolution: { value: new Vector2() },
				sdf: { value: this.stage.sdfTextures.get('regular') },
				map: { value: null },
				opacity: { value: 1 },
				mapOpacity: { value: 1 },
				offset: { value: 0 }
			}
		})

		this.object.add(this.inner)
		this.inner.renderOrder = 20
		parent.add(this.object)
	}

	async init() {
		if (this.initialized) return

		const geometries = await Promise.all(
			this.lines.map(async (line) => {
				return await Promise.all(
					line.map((word) => getTextGeometry(word, 'regular', FONT_SCALE, this.stage.sdfFonts.get('regular')))
				)
			})
		)

		this.meshes = geometries.map((line, i) => {
			this.sizes[i] = []
			return line.map(({ geometry, size }) => {
				this.sizes[i].push(size.x)
				const mesh = new Mesh(geometry, this.material)
				this.inner.add(mesh)
				return mesh
			})
		})
		this.initialized = true
	}

	updateMeshes() {
		const scale = this.baseScale
		const lineHeight = LINE_HEIGHT * scale
		const spaceWidth = SPACE_WIDTH * scale
		const max = (1 / this.fontSize) * this.width
		let maxLineWidth = 0
		let x = 0
		let y = 0

		this.meshes.forEach((meshes, i) => {
			x = 0
			y -= lineHeight
			meshes.forEach((mesh, index) => {
				const sizeX = this.sizes[i][index] * this.baseScale

				if (x + sizeX + spaceWidth > max) {
					maxLineWidth = Math.max(maxLineWidth, x)
					x = 0
					y -= lineHeight
				}

				mesh.scale.set(this.baseScale, this.baseScale, this.baseScale)
				mesh.position.x = x
				mesh.position.y = y
				x += sizeX + spaceWidth
			})
		})
		maxLineWidth = Math.max(maxLineWidth, x)

		if (this.hAlign === 'center') {
			this.inner.position.x = max * 0.5 - maxLineWidth * 0.5
		}

		if (this.vAlign === 'center') {
			this.inner.position.y = y * -0.725 //optical centering
		}

		this.height = y * this.fontSize
	}

	getBottom() {
		return this.y + this.height
	}

	setPositionY(y: number) {
		this.object.position.y = y
	}

	setBaseScale(scale: number) {
		this.baseScale = scale
		this.needsUpdate = true
	}

	setOpacity(opacity: number) {
		this.material.uniforms.opacity.value = opacity
	}

	setMapOpacity(opacity: number) {
		this.material.uniforms.mapOpacity.value = opacity
	}

	resize() {
		const { width, height, dpr } = this.stage
		this.material.uniforms.resolution.value.set(width * dpr, height * dpr)
	}

	dispose() {
		this.meshes.forEach((meshes) => {
			meshes.forEach((mesh) => {
				mesh.material.dispose()
				mesh.geometry.dispose()
			})
		})

		this.material.dispose()
	}

	update(time: number, map?: Texture | null) {
		if (!this.layer) return
		if (time < this.layer.in || time > this.layer.out) {
			this.object.visible = false
			return
		}

		this.object.visible = true
		const { aspect } = this.stage

		this.material.uniforms.map.value = map

		const width = this.layer.size[0]
		const { positionX, positionY, scaleX, opacity } = this.layer.transform
		const fontSize = scaleX

		this.fontSize = fontSize
		this.y = (positionY / aspect) * -1 + this.offsetY

		this.material.uniforms.opacity.value = opacity
		this.object.scale.set(fontSize, fontSize, fontSize)
		this.object.position.x = width * -0.5 + positionX - 0.5
		this.object.position.y = this.y

		if (this.width !== width) {
			this.width = width
			this.needsUpdate = true
		}

		if (this.fontSize !== fontSize) {
			this.fontSize = fontSize
			this.needsUpdate = true
		}

		if (this.needsUpdate) {
			this.needsUpdate = false
			this.updateMeshes()
		}
	}
}
