import { EventDispatcher, Raycaster } from 'three'
import { Stage } from './Stage'
import { App } from './App'

export const RAY_LAYER = 2

export default class RayCaster extends EventDispatcher {
	private readonly caster: Raycaster = new Raycaster()
	public hover: string | null = null

	constructor(private readonly stage: Stage, private readonly app: App) {
		super()
		this.caster.layers.set(RAY_LAYER)
		this.caster.layers.enable(RAY_LAYER)
	}

	update() {
		this.caster.setFromCamera(this.stage.devicePointer, this.stage.camera)
		const [intersect] = this.caster.intersectObjects(this.stage.scene.children)
		const href = intersect?.object.userData.href || null
		const hover = href || null

		if (this.hover !== hover) {
			this.hover = hover
			this.stage.onHover()
			this.app.onHover(this.hover)
		}
	}
}
