import {
	Color,
	Group,
	Mesh,
	Object3D,
	PlaneGeometry,
	ShaderMaterial,
	Shape,
	ShapeGeometry,
	Texture,
	Vector2
} from 'three'
import { Stage } from '../Stage'
import BaseLayer from './BaseLayer'
import Blur from './Blur'
import vertexShader from './panel.vert'
import fragmentShader from './panel.frag'

const EDGE_RADIUS_MOBILE = 0.025
const EDGE_RADIUS_DESKTOP = 0.133

export default class IntroPanel {
	private readonly object = new Group()
	private readonly corners: Mesh<ShapeGeometry, ShaderMaterial>[]
	private readonly left: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly middle: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly right: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly material: ShaderMaterial
	private readonly blur?: Blur

	constructor(
		private readonly stage: Stage,
		private readonly layer: BaseLayer | null,
		parent: Object3D,
		color: string,
		blur = false
	) {
		const shape = new Shape()
		shape.moveTo(-0.5, -0.5)
		shape.lineTo(0.5, -0.5)
		shape.quadraticCurveTo(0.5, 0.5, -0.5, 0.5)
		shape.lineTo(-0.5, -0.5)

		const geometry = new ShapeGeometry(shape, 18)
		const plane = new PlaneGeometry(1, 1)

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			uniforms: {
				map: { value: null },
				color: { value: new Color(color) },
				resolution: { value: new Vector2() },
				opacity: { value: 1 },
				noise: { value: blur ? 0.065 : 0 }
			},
			transparent: true
		})

		this.corners = [
			new Mesh(geometry, this.material),
			new Mesh(geometry, this.material),
			new Mesh(geometry, this.material),
			new Mesh(geometry, this.material)
		]

		this.left = new Mesh(plane, this.material)
		this.middle = new Mesh(plane, this.material)
		this.right = new Mesh(plane, this.material)

		if (blur) {
			this.blur = new Blur(stage)
			this.material.uniforms.map.value = this.blur.getTexture()
		}

		this.object.add(this.left)
		this.object.add(this.right)
		this.object.add(this.middle)

		this.corners.forEach((corner, index) => {
			corner.rotateZ(Math.PI * index * 0.5)
			this.object.add(corner)
		})

		this.object.renderOrder = 10
		parent.add(this.object)
	}

	setOpacity(opacity: number) {
		this.material.uniforms.opacity.value = opacity
	}

	getOpacity() {
		return this.material.uniforms.opacity.value
	}

	resize() {
		const { width, height, dpr } = this.stage
		this.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		this.blur?.resize()
	}

	dispose() {
		this.material.dispose()

		this.corners.forEach((mesh) => {
			mesh.material.dispose()
			mesh.geometry.dispose()
		})

		this.left.material.dispose()
		this.left.geometry.dispose()

		this.middle.material.dispose()
		this.middle.geometry.dispose()

		this.right.material.dispose()
		this.right.geometry.dispose()

		this.blur?.dispose()
	}

	update(time: number, map?: Texture | null) {
		if (!this.layer) return

		if (time < this.layer.in || time > this.layer.out) {
			this.object.visible = false
			return
		}

		this.object.visible = true

		const { landscape } = this.stage

		if (this.blur && map) {
			this.blur.update(map)
		}

		const width = this.layer.size[0]
		const height = this.layer.size[1]
		const y = this.layer.transform.positionY
		const scaleX = this.layer.transform.scaleX
		const scaleY = this.layer.transform.scaleY
		const opacity = this.layer.transform.opacity

		const { aspect } = this.stage
		const w = width * scaleX
		const h = (height * scaleY) / aspect
		const r = landscape ? EDGE_RADIUS_MOBILE : EDGE_RADIUS_DESKTOP
		const top = (y / aspect) * -1
		const alignTop = h * -0.5

		this.material.uniforms.opacity.value = opacity

		this.corners[0].scale.set(r, r, 1)
		this.corners[0].position.set((w - r) * 0.5, (h - r) * 0.5, 0)

		this.corners[1].scale.set(r, r, 1)
		this.corners[1].position.set((w - r) * -0.5, (h - r) * 0.5, 0)

		this.corners[2].scale.set(r, r, 1)
		this.corners[2].position.set((w - r) * -0.5, (h - r) * -0.5, 0)

		this.corners[3].scale.set(r, r, 1)
		this.corners[3].position.set((w - r) * 0.5, (h - r) * -0.5, 0)

		this.left.scale.set(r, h - 2 * r, 1)
		this.left.position.set((w - r) * -0.5, 0, 0)

		this.right.scale.set(r, h - 2 * r, 1)
		this.right.position.set((w - r) * 0.5, 0, 0)

		this.middle.scale.set(w - 2 * r, h, 1)
		this.middle.position.set(0, 0, 0)

		this.object.position.y = alignTop + top
	}
}
