import styles from './NavigationGrid.module.css'
import { Lang } from '../../lib/primsic'
import clsx from 'clsx'
import { Button } from './Button'
import { Grid } from '../grid/Grid'
import logo from '../../assets/logo.svg'

type Props = {
	lang: Lang
	loading_text?: string
}

export function NavigationGrid(props: Props): string {
	const { lang, loading_text } = props

	return /* HTML */ `
		<div class="${clsx(styles.Main, styles.Centered)}">
			<div class="${styles.Inner}">
				<a class="${styles.Logo}" href="/${lang}/">${logo}</a>
				<div class="${clsx(styles.Button, styles.MenuButton)}">${Button('navigation')}</div>
				<div class="${clsx(styles.Button, styles.LangButton)}">${Button('language')}</div>
				${loading_text ? `<div class="${styles.Loading}">${Button(loading_text, undefined, true)}</div>` : ''}
				<div class="${styles.Date}">${Button('')}</div>
			</div>
			<div class="${styles.Grid}">${Grid()}</div>
		</div>
	`
}
