import { App, Controller } from '../App'
import styles from './Table.module.css'

export class TableController implements Controller {
	private titles: HTMLElement[]
	private contents: HTMLElement[]

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.toggle = this.toggle.bind(this)
		this.titles = Array.from(node.querySelectorAll(`.${styles.Title}`) as NodeListOf<HTMLElement>)
		this.contents = Array.from(node.querySelectorAll(`.${styles.Content}`) as NodeListOf<HTMLElement>)

		this.titles.forEach((title) => title.addEventListener('click', this.toggle))
	}

	toggle(event: MouseEvent) {
		const index = this.titles.findIndex((title) => title === event.currentTarget)
		this.titles[index].classList.toggle(styles.Active)
		this.contents[index].classList.toggle(styles.Open)
	}

	dispose() {}
}
