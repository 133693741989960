import BaseLayer, { BaseLayerProps } from './BaseLayer'

type AnimationProps = {
	data: {
		layers: any[]
		height: number
		width: number
		duration: number
	}
}

export class Animation {
	private readonly ratio: number
	private readonly layers: BaseLayer[]
	private readonly baseWidth: number
	private readonly baseHeight: number

	public time = 0

	public readonly duration: number

	constructor(options: AnimationProps) {
		this.duration = options.data.duration
		this.baseWidth = options.data.width
		this.baseHeight = options.data.height
		this.ratio = options.data.width / options.data.height

		this.layers = options.data.layers.map((layer: BaseLayerProps) => {
			return new BaseLayer(layer)
		})

		this.reset()
	}

	getLayerByName(name: string): BaseLayer | null {
		const getLayer = (name: string, layer: BaseLayer): BaseLayer | null => {
			if (name === layer.name) {
				return layer
			} else if (layer.layers) {
				for (let i = 0; i < layer.layers.length; i += 1) {
					const hit = getLayer(name, layer.layers[i])
					if (hit) {
						return hit
					}
				}
			}
			return null
		}

		return this.layers.find((layer) => getLayer(name, layer)) || null
	}

	reset() {
		this.time = 0
		this.layers.forEach((layer) => layer.reset())
	}

	setKeyframes(time: number) {
		this.layers.forEach((layer) => layer.setKeyframes(time))
	}

	update(step: number) {
		this.time = step * this.duration
		this.setKeyframes(this.time)

		this.layers.forEach((layer) => {
			if (this.time >= layer.in && this.time <= layer.out) {
				layer.update(this.time)
			}
		})
	}
}
