import {
	BufferAttribute,
	Color,
	Float32BufferAttribute,
	Group,
	InstancedBufferAttribute,
	InstancedBufferGeometry,
	InterleavedBufferAttribute,
	Line,
	Mesh,
	Object3D,
	PlaneGeometry,
	Points,
	ShaderMaterial,
	Texture,
	Vector2
} from 'three'

import { Stage } from '../Stage'
import vertexShader from './grid.vert'
import fragmentShader from './grid.frag'
import lineVertexShader from './line.vert'
import lineFragmentShader from './line.frag'
import labelVertexShader from './label.vert'
import labelFragmentShader from './label.frag'
import BaseLayer from './BaseLayer'
import { COLORS } from '../../../const'

const EDGE_SIZE = 40
const CROSS_SIZE = 30
const TEXT_ASPECT = 11.71

export default class Grid {
	private readonly object = new Object3D()
	private readonly inner = new Group()

	private readonly edges: Points<InstancedBufferGeometry, ShaderMaterial>
	private readonly crosses: Points<InstancedBufferGeometry, ShaderMaterial>
	private readonly lines: Line<InstancedBufferGeometry, ShaderMaterial>
	private readonly linePosition: Float32BufferAttribute
	private readonly translate: InstancedBufferAttribute
	private readonly opacity: InstancedBufferAttribute
	private readonly edgePosition: Float32BufferAttribute
	private readonly crossPosition: Float32BufferAttribute
	private readonly text: Mesh<InstancedBufferGeometry, ShaderMaterial>
	private readonly textPosition: BufferAttribute | InterleavedBufferAttribute

	constructor(
		private readonly stage: Stage,
		public readonly layer: BaseLayer | null,
		parent: Object3D,
		private readonly numX = 1,
		private readonly numY = 1
	) {
		const rotation = [Math.PI, Math.PI * 0.5, 0, Math.PI * 1.5]
		const translate = new Float32Array(numX * numY * 3).fill(0)
		const opacity = new Float32Array(numX * numY).fill(1)

		const edgeMaterial = new ShaderMaterial({
			uniforms: {
				uvOffset: { value: new Vector2(0.0, 0.75) },
				size: { value: EDGE_SIZE },
				resolution: { value: new Vector2() },
				pointMap: { value: this.stage.iconsTexture },
				map: { value: null },
				color: { value: new Color(COLORS.grey01) },
				opacity: { value: 0 }
			},
			vertexShader,
			fragmentShader,
			transparent: true
		})

		//prettier-ignore
		const edgePositions = [
			-0.5, 0.5, 0,
			0.5, 0.5, 0,
			0.5, -0.5, 0,
			-0.5, -0.5, 0
		]

		this.translate = new InstancedBufferAttribute(translate, 3)
		this.opacity = new InstancedBufferAttribute(opacity, 1)
		this.edgePosition = new Float32BufferAttribute(edgePositions, 3)

		const edgeGeometry = new InstancedBufferGeometry()
		edgeGeometry.instanceCount = numX * numY
		edgeGeometry.setAttribute('position', this.edgePosition)
		edgeGeometry.setAttribute('rotation', new Float32BufferAttribute(rotation, 1))
		edgeGeometry.setAttribute('translate', this.translate)
		edgeGeometry.setAttribute('opacity', this.opacity)

		this.edges = new Points(edgeGeometry, edgeMaterial)
		this.inner.add(this.edges)

		const crossMaterial = new ShaderMaterial({
			uniforms: {
				uvOffset: { value: new Vector2(0.25, 0.75) },
				size: { value: CROSS_SIZE },
				resolution: { value: new Vector2() },
				pointMap: { value: this.stage.iconsTexture },
				map: { value: null },
				color: { value: new Color(COLORS.grey01) },
				opacity: { value: 0 }
			},
			vertexShader,
			fragmentShader,
			transparent: true
		})

		//prettier-ignore
		const crossPositions = [
			0, 0.5, 0,
			0.5, 0, 0,
			0, -0.5, 0,
			-0.5, 0, 0
		]

		const crossGeometry = new InstancedBufferGeometry()
		crossGeometry.instanceCount = numX * numY
		this.crossPosition = new Float32BufferAttribute(crossPositions, 3)
		crossGeometry.setAttribute('position', this.crossPosition)
		crossGeometry.setAttribute('translate', this.translate)
		crossGeometry.setAttribute('opacity', this.opacity)

		this.crosses = new Points(crossGeometry, crossMaterial)
		this.inner.add(this.crosses)

		const lineMaterial = new ShaderMaterial({
			uniforms: {
				resolution: { value: new Vector2() },
				color: { value: new Color(COLORS.grey01) },
				opacity: { value: 0 },
				map: { value: null }
			},
			vertexShader: lineVertexShader,
			fragmentShader: lineFragmentShader,
			transparent: true
		})

		//prettier-ignore
		const linesPositions = [
			-0.5, 0.5, 0,
			0.5, 0.5, 0,
			0.5, -0.5, 0,
			-0.5, -0.5, 0,
			-0.5, 0.5, 0
		]

		const linesGeometry = new InstancedBufferGeometry()
		linesGeometry.instanceCount = numX * numY
		this.linePosition = new Float32BufferAttribute(linesPositions, 3)
		linesGeometry.setAttribute('position', this.linePosition)
		linesGeometry.setAttribute('translate', this.translate)
		linesGeometry.setAttribute('opacity', this.opacity)

		this.lines = new Line(linesGeometry, lineMaterial)
		this.inner.add(this.lines)

		const textMaterial = new ShaderMaterial({
			uniforms: {
				uvOffset: { value: new Vector2(0.0, 0.5) },
				resolution: { value: new Vector2() },
				diffuseMap: { value: this.stage.iconsTexture },
				map: { value: null },
				color: { value: new Color(COLORS.grey01) },
				opacity: { value: 0 }
			},
			vertexShader: labelVertexShader,
			fragmentShader: labelFragmentShader,
			transparent: true
		})

		const plane = new PlaneGeometry(2, 2)
		const textGeometry = new InstancedBufferGeometry()
		this.textPosition = plane.getAttribute('position')
		textGeometry.instanceCount = numX * numY
		textGeometry.setIndex(plane.getIndex())
		textGeometry.setAttribute('position', this.textPosition)
		textGeometry.setAttribute('uv', plane.getAttribute('uv'))
		textGeometry.setAttribute('translate', this.translate)
		textGeometry.setAttribute('opacity', this.opacity)
		this.text = new Mesh(textGeometry, textMaterial)

		this.inner.add(this.text)

		this.inner.renderOrder = 30
		this.object.add(this.inner)
		parent.add(this.object)
	}

	getTranslate() {
		return this.translate
	}

	setOpacity(opacity: number) {
		this.edges.material.uniforms.opacity.value = opacity
		this.crosses.material.uniforms.opacity.value = opacity
		this.lines.material.uniforms.opacity.value = opacity
		this.text.material.uniforms.opacity.value = opacity
	}

	resize() {
		const { width, height, dpr } = this.stage
		this.edges.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		this.crosses.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		this.lines.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		this.text.material.uniforms.resolution.value.set(width * dpr, height * dpr)
	}

	dispose() {
		this.edges.material.dispose()
		this.crosses.material.dispose()
		this.lines.material.dispose()
		this.text.material.dispose()
	}

	update(time: number, map?: Texture | null) {
		if (!this.layer) return
		if (time < this.layer.in || time > this.layer.out) {
			this.object.visible = false
			return
		}

		this.object.visible = true
		const { aspect, width: stageWidth, height: stageHeight, dpr, landscape } = this.stage

		if (map) {
			this.edges.material.uniforms.map.value = map
			this.crosses.material.uniforms.map.value = map
			this.lines.material.uniforms.map.value = map
			this.text.material.uniforms.map.value = map
		}

		const width = this.layer.size[0]
		const height = this.layer.size[1] / aspect
		const scale = this.layer.transform.scaleX
		const opacity = this.layer.transform.opacity
		const slider = this.layer.slider || 0

		this.inner.position.y = -0.5 / aspect

		this.edges.material.uniforms.opacity.value = opacity
		this.crosses.material.uniforms.opacity.value = opacity
		this.lines.material.uniforms.opacity.value = opacity
		this.text.material.uniforms.opacity.value = opacity

		const edgeSize = landscape ? EDGE_SIZE : EDGE_SIZE * 0.5
		const crossSize = landscape ? CROSS_SIZE : CROSS_SIZE * 0.5

		const halfPointSizeX = (edgeSize / stageWidth) * 0.5
		const halfPointSizeY = ((edgeSize / stageHeight) * 0.5) / aspect

		this.edges.material.uniforms.size.value = edgeSize * scale * dpr
		this.crosses.material.uniforms.size.value = crossSize * scale * dpr

		const left = width * -0.5
		const right = width * 0.5
		const top = height * 0.5
		const bottom = height * -0.5

		this.linePosition.setXYZ(0, left + halfPointSizeX, top - halfPointSizeY, 0)
		this.linePosition.setXYZ(1, right - halfPointSizeX, top - halfPointSizeY, 0)
		this.linePosition.setXYZ(2, right - halfPointSizeX, bottom + halfPointSizeY, 0)
		this.linePosition.setXYZ(3, left + halfPointSizeX, bottom + halfPointSizeY, 0)
		this.linePosition.setXYZ(4, left + halfPointSizeX, top - halfPointSizeY, 0)

		this.edgePosition.setXYZ(0, left, top, 0)
		this.edgePosition.setXYZ(1, right, top, 0)
		this.edgePosition.setXYZ(2, right, bottom, 0)
		this.edgePosition.setXYZ(3, left, bottom, 0)

		this.crossPosition.setXYZ(0, 0, top, 0)
		this.crossPosition.setXYZ(1, right, 0, 0)
		this.crossPosition.setXYZ(2, 0, bottom, 0)
		this.crossPosition.setXYZ(3, left, 0, 0)

		const textWidth = halfPointSizeY * TEXT_ASPECT * 0.5

		this.textPosition.setXYZ(0, right - halfPointSizeX * 2 - textWidth, bottom + halfPointSizeY * 0.25, 0)
		this.textPosition.setXYZ(1, right - halfPointSizeX * 2, bottom + halfPointSizeY * 0.25, 0)
		this.textPosition.setXYZ(2, right - halfPointSizeX * 2 - textWidth, bottom - halfPointSizeY * 0.25, 0)
		this.textPosition.setXYZ(3, right - halfPointSizeX * 2, bottom - halfPointSizeY * 0.25, 0)

		const paddingX = halfPointSizeX * 4
		const paddingY = halfPointSizeY * 4

		this.inner.scale.set(scale, scale, 1)

		const halfX = Math.floor(this.numX * 0.5)
		const halfY = Math.floor(this.numY * 0.5)

		for (let i = 0; i < this.numY; i++) {
			for (let ii = 0; ii < this.numX; ii++) {
				const x = ii - halfX
				const y = i - halfY
				const index = i * this.numX + ii

				const distToCenter = Math.abs(x) + Math.abs(y)
				const opacity = distToCenter > 0 ? slider : 1

				const offsetX = x * (width + paddingX)
				const offsetY = y * (height + paddingY)

				this.opacity.setX(index, opacity)
				this.translate.setXYZ(index, offsetX, offsetY, 0)
			}
		}

		this.opacity.needsUpdate = true
		this.translate.needsUpdate = true
		this.linePosition.needsUpdate = true
		this.edgePosition.needsUpdate = true
		this.crossPosition.needsUpdate = true
		this.textPosition.needsUpdate = true
	}
}
