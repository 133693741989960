import Transform, { TransformProps } from './Transform'
import AnimatedProperty from './AnimatedProperty'
import Property from './Property'

export type BaseLayerProps = {
	shapes: any
	groups: { shapes: { size: [] }[] }[]
	effects: { sliderControl: { slider: [] } }
	color: string
	font: string
	fontSize: number
	leading: number
	text: string
	timeRemapping: []
	source: string
	sourceID: string
	name: string
	index: number
	in?: number
	out: number
	transform: TransformProps
}

class BaseLayer {
	public name: string

	public index: number
	public in: number
	public out: number
	public transform: Transform

	public layers: BaseLayer[] = []
	public sizeProperty: Property<[number, number]>
	public size: [number, number] = [0, 0]
	public sliderProperty?: Property<number>
	public slider? = 0

	constructor(data: BaseLayerProps) {
		this.name = data.name
		this.index = data.index
		this.in = data.in || 0
		this.out = data.out
		this.transform = new Transform(data.transform)

		const slider = data.effects.sliderControl?.slider
		this.sliderProperty = slider
			? slider.length > 1
				? new AnimatedProperty(slider)
				: new Property(slider)
			: undefined

		const size = data.groups[0].shapes[0].size
		this.sizeProperty = size.length > 1 ? new AnimatedProperty(size) : new Property(size)
	}

	setKeyframes(time: number) {
		this.transform.setKeyframes(time)
	}

	reset() {
		this.transform.reset()
	}

	update(time: number) {
		this.transform.update(time)
		this.size = this.sizeProperty.getValue(time)
		if (this.sliderProperty) {
			this.slider = this.sliderProperty.getValue(time)
		}
	}
}

export default BaseLayer
