import { App, Controller } from '../App'
import { delay } from '../../utils/delay'

export class NodeController implements Controller {
	private readonly classname: string

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onIntersect = this.onIntersect.bind(this)
		this.classname = this.node.dataset.visible || ''
	}

	async show(animate = false) {
		if (animate) {
			await delay(3000)
		}

		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				entry.isIntersecting
					? this.node.classList.add(this.classname)
					: this.node.classList.remove(this.classname)
				if (entry.isIntersecting) {
					this.dispose()
				}
			}
		})
	}

	dispose() {
		this.app.removeListener('intersect', this.onIntersect)
		this.app.intersectionObserver.unobserve(this.node)
	}
}
