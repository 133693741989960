import styles from './Products.module.css'
import { isFilled } from '@prismicio/helpers'
import { PageDocumentDataBodyProductsSlice } from '../../@types/types.generated'
import clsx from 'clsx'

const Icon = /* HTML */ `
	<svg class="${styles.Icon}" viewBox="0 0 84 84" xmlns="http://www.w3.org/2000/svg">
		<circle class="${styles.Bg}" cx="42" cy="42" r="42" />
		<circle class="${styles.Circle}" cx="42" cy="42" r="40" />
		<path class="${styles.Arrow}" d="M42.0703 49.1426L34.9992 42.0715L42.0703 35.0004" />
		<path class="${styles.Arrow}" d="M35 42.071L49.1421 42.071" />
	</svg>
`

export const Products = (props: PageDocumentDataBodyProductsSlice): string => {
	const {
		primary: { file }
	} = props

	const items = props.items.map(({ id, name, texture }) => ({ id, name, texture: texture?.url || '' }))

	return /* HTML */ `<div class="${styles.Main}" data-items="${encodeURI(JSON.stringify(items))}" ${
		isFilled.linkToMedia(file) ? `data-file="${file.url}` : ''
	}">
		<div class="${styles.Container}">
			<div class="${styles.Labels}">${items
		.map((item) => {
			const length = item.name?.length || 0

			return /* HTML */ `<div
				class="${clsx(
					styles.Label,
					length === 3 && styles.Large,
					length === 4 && styles.Medium,
					length > 4 && styles.Small
				)}"
			>
				${item.name
					?.split('')
					.map(
						(name, index) => `<span class="${styles.Char}" style="--delay: ${index * 100}ms">${name}</span>`
					)
					.join('')}
			</div>`
		})
		.join('')}</div>
		<div class="${styles.Controls}">
			<button class="${clsx(styles.Button, styles.Prev)}">${Icon}</button>
			<button class="${clsx(styles.Button, styles.Next)}">${Icon}</button>
		</div>
			<canvas class="${styles.Canvas}"></canvas>
		</div>
	</div>`
}
