import styles from './Cards.module.css'
import { App, Controller } from '../App'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import { Card } from './Card'
import { delay } from '../../utils/delay'

export class CardsController implements Controller {
	private readonly cards: Card[]
	private readonly container: HTMLElement
	private readonly bg: HTMLElement
	private readonly hasScrollEndEvent: boolean

	public readonly group = new Group()

	private activeIndex: number | null = null
	private tween?: Tween<{ scale?: number; offset?: number }>

	private left = 0
	private width = 0
	private offset = 0
	private scale = 0
	private scrollEndTimer = 0
	private needsScrollUpdate = false
	private cardWidth = 0

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onIntersect = this.onIntersect.bind(this)
		this.onOver = this.onOver.bind(this)
		this.onOut = this.onOut.bind(this)
		this.onNodeOut = this.onNodeOut.bind(this)
		this.onScroll = this.onScroll.bind(this)
		this.onScrollEnd = this.onScrollEnd.bind(this)

		this.container = node.querySelector(`.${styles.Cards}`) as HTMLElement
		const nodes = Array.from(node.querySelectorAll(`.${styles.Card}`) as NodeListOf<HTMLElement>)
		this.cards = nodes.map((node, index) => new Card(node, index, this.onOver, this.onOut, this, app))
		this.bg = node.querySelector(`.${styles.BG}`) as HTMLElement

		this.cards.forEach((card) => card.init())

		this.node.addEventListener('mouseleave', this.onNodeOut)

		this.hasScrollEndEvent = 'onscrollend' in window
		if (this.hasScrollEndEvent) {
			this.container.addEventListener('scrollend', this.onScrollEnd)
		} else {
			this.container.addEventListener('scroll', this.onScroll)
		}

		this.resize()
	}

	async show() {
		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	onOver(index: number) {
		if (this.activeIndex === null) {
			this.offset = index
		}

		this.cards.forEach((card) => card.unsetActive())
		this.cards[index].setActive()
		this.activeIndex = index
		this.snapBG()
	}

	onOut() {
		this.cards.forEach((card) => card.unsetActive())
	}

	onScroll() {
		this.needsScrollUpdate = true
		if (!this.hasScrollEndEvent) {
			window.clearTimeout(this.scrollEndTimer)
			this.scrollEndTimer = window.setTimeout(this.onScrollEnd, 100)
		}
	}

	onScrollEnd() {
		const left = this.container.scrollLeft
		const index = Math.round(left / this.cardWidth)
		if (this.activeIndex === index) return
		this.cards.forEach((card) => card.unsetActive())
		this.activeIndex = index
		this.cards[index].setActive()
	}

	onNodeOut(): void {
		this.activeIndex = null
		this.tween?.stop()
		this.tween = new Tween({ scale: 1 }, this.group)
			.to({ scale: 0 }, 500)
			.easing(Easing.Cubic.InOut)
			.onUpdate(({ scale }) => {
				this.scale = scale
				const x = Math.floor((this.offset / this.cards.length) * this.width) - 1
				this.bg.style.setProperty('transform', `translateX(${x}px) scale(1, ${scale})`)
			})
			.start()
	}

	snapBG() {
		this.tween?.stop()
		this.tween = new Tween({ offset: this.offset, scale: this.scale }, this.group)
			.to({ offset: this.activeIndex, scale: 1 }, 500)
			.easing(Easing.Cubic.Out)
			.onUpdate(({ offset, scale }) => {
				this.offset = offset
				this.scale = scale
				const x = Math.floor((this.offset / this.cards.length) * this.width) - 1
				this.bg.style.setProperty('transform', `translateX(${x}px) scale(${scale}, 1)`)
			})
			.start()
	}

	onIntersect(entries: IntersectionObserverEntry[]) {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				if (entry.isIntersecting) {
					this.showCards()
				} else {
					this.cards.forEach((card) => card.hide())
				}
			}
		})
	}

	async showCards() {
		const { isDesktop } = this.app
		this.activeIndex = 0
		this.cards.forEach((card, index) => card.show(index * 50))
		if (isDesktop) {
			await delay(this.cards.length * 50 + 1000)
		}
		this.cards[0].setActive()
		this.snapBG()
	}

	dispose() {
		this.cards.forEach((card) => card.dispose())
		this.group.removeAll()
		this.node.removeEventListener('mouseleave', this.onNodeOut)
		if (this.hasScrollEndEvent) {
			this.container.removeEventListener('scrollend', this.onScrollEnd)
		} else {
			this.container.removeEventListener('scroll', this.onScroll)
		}
		window.clearTimeout(this.scrollEndTimer)
	}

	resize(): void {
		const { left, width } = this.node.getBoundingClientRect()
		this.left = left
		this.width = width
		this.cards.forEach((card) => card.resize())
		this.cardWidth = this.cards[0].getWidth()
	}

	update(time: number) {
		this.group.update(time)
	}
}
