import { PanelSlice } from '../templates'
import {
	PageDocumentDataBodyAnchorSlice,
	PageDocumentDataBodyButtonSlice,
	PageDocumentDataBodyCardsSlice,
	PageDocumentDataBodyColumnsSlice,
	PageDocumentDataBodyFluidTitleSlice,
	PageDocumentDataBodyGallerySlice,
	PageDocumentDataBodyIntroSlice,
	PageDocumentDataBodyLargeTextSlice,
	PageDocumentDataBodyLeadSlice,
	PageDocumentDataBodyLinklistSlice,
	PageDocumentDataBodyMachinesSlice,
	PageDocumentDataBodyProductsSlice,
	PageDocumentDataBodySpacerSlice,
	PageDocumentDataBodyTableSlice,
	PageDocumentDataBodyTextSlice,
	PageDocumentDataBodyTitleSlice
} from '../@types/types.generated'

export type CustomSlice =
	| PageDocumentDataBodyProductsSlice
	| PageDocumentDataBodyCardsSlice
	| PageDocumentDataBodyTableSlice
	| PageDocumentDataBodyTextSlice
	| PageDocumentDataBodyMachinesSlice
	| PageDocumentDataBodyGallerySlice
	| PageDocumentDataBodyButtonSlice
	| PageDocumentDataBodyLargeTextSlice
	| PageDocumentDataBodyAnchorSlice
	| PageDocumentDataBodyTitleSlice
	| PageDocumentDataBodyLinklistSlice
	| PageDocumentDataBodyLeadSlice
	| PageDocumentDataBodyColumnsSlice
	| PageDocumentDataBodyIntroSlice
	| PageDocumentDataBodyFluidTitleSlice
	| PageDocumentDataBodySpacerSlice
	| PanelSlice

export const prepareSlices = (slices: CustomSlice[]) => {
	let panel: PanelSlice | null = null

	return slices.reduce((slices: CustomSlice[], slice) => {
		if (slice.slice_type === 'intro' || slice.slice_type === 'fluid_title' || slice.slice_type === 'spacer') {
			slices.push(slice)
			panel = null
		} else {
			if (panel === null) {
				panel = { slice_type: 'panel', slices: [] }
				slices.push(panel)
			}
			panel.slices.push(slice)
		}
		return slices
	}, [])
}
