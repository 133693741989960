import { Group, Tween } from '@tweenjs/tween.js'
import pageStyles from './Page.module.css'
import fluidTitleStyles from './fluidTitle/FluidTitle.module.css'

export class PageController {
	private readonly group = new Group()
	private main?: HTMLElement
	private inner?: HTMLElement
	private opacity = 0

	public fluidTitles: HTMLElement[] = []
	public intro: string[] | null = null

	constructor() {
		this.selectNodes()
	}

	selectNodes() {
		this.main = document.querySelector(`.${pageStyles.Main}`) as HTMLElement
		this.inner = this.main.querySelector(`.${pageStyles.Inner}`) as HTMLElement

		this.fluidTitles = Array.from(this.main.querySelectorAll(`.${fluidTitleStyles.Main}`)) as HTMLElement[]
		const text = this.main?.dataset?.intro as string
		this.intro = text ? JSON.parse(decodeURI(text)) : null
	}

	onMenuOpen() {
		this.group.removeAll()
		new Tween({ opacity: this.opacity }, this.group)
			.to({ opacity: 0 }, 250)
			.onUpdate(({ opacity }) => {
				this.opacity = opacity
				this.inner?.style.setProperty('opacity', `${opacity}`)
			})
			.onComplete(() => {
				this.opacity = 0
				this.inner?.style.setProperty('display', 'none')
			})
			.start()
	}

	onMenuClose() {
		this.group.removeAll()
		new Tween({ opacity: 0 }, this.group)
			.to({ opacity: 1 }, 250)
			.onStart(() => {
				this.inner?.style.setProperty('opacity', '0')
				this.inner?.style.removeProperty('display')
			})
			.onUpdate(({ opacity }) => {
				this.opacity = opacity
				this.inner?.style.setProperty('opacity', `${opacity}`)
			})
			.onComplete(() => {
				this.opacity = 1
				this.inner?.style.setProperty('opacity', `1`)
			})
			.start()
	}

	updateDOM(node: HTMLElement) {
		this.main?.replaceWith(node)

		this.selectNodes()

		// this.panel?.style.setProperty('opacity', '0')
	}

	setFixed(scrollY: number): void {
		this.inner?.style.setProperty('transform', `translateY(${scrollY * -1}px)`)
	}

	unsetFixed(): void {
		this.inner?.style.removeProperty('transform')
	}

	getBoundingClientRect() {
		return this.main?.getBoundingClientRect()
	}

	update(time: number): void {
		this.group.update(time)
	}
}
