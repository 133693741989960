import { App, Controller } from '../App'
import {
	BufferGeometry,
	MathUtils,
	Mesh,
	MeshBasicMaterial,
	Object3D,
	PerspectiveCamera,
	Raycaster,
	Scene,
	SRGBColorSpace,
	TextureLoader,
	Vector2,
	WebGLRenderer
} from 'three'
import styles from './Products.module.css'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { isMesh } from '../../utils/isMesh'
import { Group } from '@tweenjs/tween.js'
import { Shadow } from './Shadow'

const FRICTION = 0.95
const VELOCITY_MULTIPLIER = 20
const THRESHOLD = 0.01
const ROTATION_MULTIPLIER = 1.1
const RADIUS = 1.75
const CAM_Z = 3.25

export class ProductsController implements Controller {
	private readonly camera: PerspectiveCamera
	private readonly container: HTMLCanvasElement
	private readonly products: { [key: string]: Object3D } = {}
	private readonly caster: Raycaster = new Raycaster()
	private readonly pointer = new Vector2()
	private readonly lastPointer = new Vector2()
	private readonly velocity = new Vector2()
	private readonly activePointers: PointerEvent[] = []
	private readonly group: Group = new Group()
	private readonly labels: HTMLElement[] = []
	private readonly items: { id: string; name: string; texture: string }[]
	private readonly file?: string
	private readonly shadow: Shadow

	private rotationTarget = 0
	private rotation = 0
	private object: Object3D = new Object3D()
	private objects: Object3D[] = []
	private rands: number[] = []
	private isDecelerating = false
	private points: { x: number; time: number }[] = []

	public readonly renderer: WebGLRenderer
	public readonly scene: Scene
	public readonly meshes: Mesh<BufferGeometry, MeshBasicMaterial>[] = []
	public readonly materials: MeshBasicMaterial[] = []
	private inView = false
	private prevButton: HTMLButtonElement
	private nextButton: HTMLButtonElement
	private activeIndex = 0
	private hover: number | null = null
	private width = 0
	private height = 0
	private left = 0
	private top = 0

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onClick = this.onClick.bind(this)
		this.onIntersect = this.onIntersect.bind(this)
		this.showPrev = this.showPrev.bind(this)
		this.showNext = this.showNext.bind(this)
		this.onPointerStart = this.onPointerStart.bind(this)
		this.onPointerMove = this.onPointerMove.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)

		this.items = JSON.parse(decodeURI(this.node.dataset?.items || ''))
		this.file = this.node.dataset.file

		this.container = node.querySelector(`.${styles.Container}`) as HTMLCanvasElement
		const canvas = this.container.querySelector('canvas') as HTMLCanvasElement
		this.prevButton = node.querySelector(`.${styles.Prev}`) as HTMLButtonElement
		this.nextButton = node.querySelector(`.${styles.Next}`) as HTMLButtonElement

		this.labels = Array.from(node.querySelectorAll(`.${styles.Label}`) as NodeListOf<HTMLElement>)

		this.scene = new Scene()
		this.camera = new PerspectiveCamera()
		this.camera.position.z = CAM_Z

		this.renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas })
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		this.renderer.autoClear = false

		this.shadow = new Shadow(this)

		this.object.position.y = 0.15

		this.scene.add(this.object)

		this.resize()
	}

	async load() {
		if (!this.file) return

		const [gltf, textures] = await Promise.all([
			new GLTFLoader().loadAsync(this.file),
			Promise.all(this.items.map(({ texture }) => new TextureLoader().loadAsync(`${texture}&w=${2048}`)))
		])

		textures.forEach((texture) => {
			texture.flipY = false
			texture.colorSpace = SRGBColorSpace
		})

		this.items.forEach(({ id }, index) => {
			const mesh = gltf.scene.getObjectByName(id)

			if (mesh && isMesh(mesh)) {
				const material = new MeshBasicMaterial({ map: textures[index] })
				this.materials.push(material)
				mesh.material = material
				mesh.userData.index = index
				const group = new Object3D()
				this.products[mesh.name] = group
				this.meshes.push(mesh as Mesh<BufferGeometry, MeshBasicMaterial>)
				this.objects.push(group)

				group.add(mesh)
				this.object.add(group)

				this.rands.push(Math.random())

				const angle = ((Math.PI * 2) / this.items.length) * index - Math.PI * -0.5

				const x = RADIUS * Math.cos(angle)
				const z = RADIUS * Math.sin(angle)

				group.position.x = x
				group.position.z = z
			}
		})

		this.renderer.compile(this.scene, this.camera)

		this.prevButton.addEventListener('click', this.showPrev)
		this.nextButton.addEventListener('click', this.showNext)
		this.container.addEventListener('click', this.onClick)
		this.container.addEventListener('pointerdown', this.onPointerStart)
		this.container.addEventListener('pointermove', this.onPointerMove)
		this.container.addEventListener('pointerup', this.onPointerEnd)
		this.container.addEventListener('pointercancel', this.onPointerEnd)
		this.container.addEventListener('pointerleave', this.onPointerEnd)
	}

	async show() {
		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	onClick() {
		if (this.hover === null || this.hover === this.activeIndex) {
			return
		}

		const len = this.items.length
		let diff = this.activeIndex - this.hover

		if (diff === len - 1) {
			diff -= len
		}

		if (diff === (len - 1) * -1) {
			diff += len
		}

		this.rotationTarget -= diff
	}

	showNext() {
		this.rotationTarget--
	}

	showPrev() {
		this.rotationTarget++
	}

	setLabel() {
		this.labels.forEach((label, index) =>
			index === this.activeIndex ? label.classList.add(styles.Visible) : label.classList.remove(styles.Visible)
		)
	}

	onPointerStart(event: PointerEvent): void {
		const time = performance.now()
		const x = ((event.x - this.left) / this.width) * 2 - 1
		const y = -((event.y - (this.top - scrollY)) / this.height) * 2 + 1
		this.pointer.set(x, y)
		this.lastPointer.set(x, y)
		this.isDecelerating = false
		this.activePointers.push(event)
		this.points = []
		this.points.push({ x, time })
	}

	onPointerMove(event: PointerEvent): void {
		const { scrollY } = this.app
		const x = ((event.x - this.left) / this.width) * 2 - 1
		const y = -((event.y - (this.top - scrollY)) / this.height) * 2 + 1
		this.pointer.set(x, y)

		for (let i = 0; i < this.activePointers.length; i++) {
			if (event.pointerId === this.activePointers[i].pointerId) {
				this.activePointers[i] = event
				break
			}
		}

		if (this.activePointers.length === 1) {
			const time = performance.now()

			while (this.points.length) {
				if (time - this.points[0].time <= 100) {
					break
				}
				this.points.shift()
			}

			this.points.push({ x, time })
		}
	}

	onPointerEnd(event: PointerEvent): void {
		const index = this.activePointers.findIndex(({ pointerId }) => pointerId === event.pointerId)
		if (index === -1) return

		this.activePointers.splice(index, 1)

		if (this.activePointers.length === 0) {
			const time = performance.now()
			const x = ((event.x - this.left) / this.width) * 2 - 1
			this.points.push({ x, time })

			const firstPoint = this.points[0]
			const lastPoint = this.points[this.points.length - 1]

			const xOffset = lastPoint.x - firstPoint.x
			const duration = lastPoint.time - firstPoint.time

			this.velocity.x = xOffset / duration || 0
			this.velocity.x *= VELOCITY_MULTIPLIER

			this.isDecelerating = Math.abs(this.velocity.x) > THRESHOLD
		}
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				this.inView = entry.isIntersecting
				if (this.inView) {
					this.prevButton.classList.add(styles.Visible)
					this.nextButton.classList.add(styles.Visible)
					this.setLabel()
				}
			}
		})
	}

	resize(): void {
		const { scrollY } = this.app
		const { width, height, left, top } = this.container.getBoundingClientRect()
		this.width = width
		this.height = height
		this.left = left
		this.top = top + scrollY

		this.camera.aspect = width / height
		this.camera.updateProjectionMatrix()
		this.renderer.setSize(width, height)
	}

	dispose() {
		this.shadow.dispose()

		this.meshes.forEach((mesh) => {
			mesh.material.map?.dispose()
			mesh.material.dispose()
			mesh.geometry.dispose()
		})

		this.scene.children.forEach((child) => this.scene.remove(child))
		this.renderer.dispose()

		this.container.removeEventListener('click', this.onClick)
		this.container.removeEventListener('pointerdown', this.onPointerStart)
		this.container.removeEventListener('pointermove', this.onPointerMove)
		this.container.removeEventListener('pointerup', this.onPointerEnd)
		this.container.removeEventListener('pointercancel', this.onPointerEnd)
		this.container.removeEventListener('pointerleave', this.onPointerEnd)

		this.app.removeListener('intersect', this.onIntersect)
		this.app.intersectionObserver.unobserve(this.node)

		this.prevButton.removeEventListener('click', this.showPrev)
		this.nextButton.removeEventListener('click', this.showNext)
	}

	update(time: number) {
		if (!this.inView) return

		this.group.update()

		this.caster.setFromCamera(this.pointer, this.camera)
		const [intersect] = this.caster.intersectObjects(this.meshes)
		const hover = intersect?.object.userData.index ?? null

		if (this.hover !== hover) {
			this.hover = hover
			if (this.hover !== this.activeIndex) {
				this.app.onHover(this.hover)
			}
		}

		if (this.isDecelerating) {
			this.velocity.x *= FRICTION
			this.rotationTarget += this.velocity.x * ROTATION_MULTIPLIER
			this.isDecelerating = Math.abs(this.velocity.x) > THRESHOLD
		}

		if (this.activePointers.length === 1) {
			const x = this.pointer.x - this.lastPointer.x
			this.lastPointer.copy(this.pointer)
			this.rotationTarget += x * ROTATION_MULTIPLIER
		} else {
			const rounded = Math.round(this.rotationTarget)
			this.rotationTarget += (rounded - this.rotationTarget) * 0.1
		}

		this.rotation += (this.rotationTarget - this.rotation) * 0.1
		const angle = ((Math.PI * 2) / this.items.length) * this.rotation

		this.object.rotation.y = angle

		let modulo = this.rotation % this.items.length
		modulo = modulo < 0 ? modulo + this.items.length : modulo

		const moduloReversed =
			modulo > this.items.length * 0.5 ? modulo - this.items.length : modulo + this.items.length

		this.objects.forEach((object, index) => {
			const diff = Math.min(Math.abs(index - modulo), Math.abs(index - moduloReversed))
			const scale = MathUtils.clamp(2 - Math.pow(diff, 2), 0, 1)
			const rand = this.rands[index]
			const mesh = this.meshes[index]
			const sin = Math.sin((time + 10000 * rand) * 0.001 + rand * 0.002)
			const cos = Math.cos((time + 20000 * rand) * 0.001 + rand * 0.002)

			object.rotation.x = sin * 0.05
			object.rotation.z = cos * 0.05
			object.scale.set(scale, scale, scale)

			object.rotation.y = angle * -1
			mesh.position.set(cos * (0.015 + rand * 0.015), sin * (0.025 + rand * 0.025), 0)
		})

		const activeIndex = Math.round(modulo) % this.items.length

		if (this.activeIndex !== activeIndex) {
			this.activeIndex = activeIndex
			this.setLabel()
		}

		this.shadow.update()
		this.meshes.forEach((mesh, index) => (mesh.material = this.materials[index]))
		this.renderer.setRenderTarget(null)
		this.renderer.clear()
		this.renderer.render(this.scene, this.camera)
	}
}
