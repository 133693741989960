export function getDateString() {
	const date = new Date()
	return `${date.toLocaleDateString(document.documentElement.lang, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	})} ${date.toLocaleTimeString(document.documentElement.lang, {
		minute: '2-digit',
		hour: '2-digit'
	})}`
}
