import styles from './Cards.module.css'
import typoStyles from '../Typography.module.css'
import clsx from 'clsx'
import { asHTML } from '@prismicio/helpers'
import { PageDocumentDataBodyCardsSlice } from '../../@types/types.generated'

export const Cards = (props: PageDocumentDataBodyCardsSlice): string => {
	const {
		primary: { background },
		items
	} = props
	return /* HTML */ `<div class="${clsx(styles.Main, background === 'grey 02' && styles.Grey02)}">
		<div class="${styles.Inner}">
			<div class="${styles.BG}" style="--width:${100 / items.length}%;"></div>

			${items.length > 0
				? /* HTML */ `<div class="${styles.Cards}">
						${items
							.map(
								({ title, subtitle, text, animation }, index) => /* HTML */ `<div
									class="${styles.Card}"
									${animation ? `data-id="${animation}"` : ''}
								>
									<div class="${styles.Index}">
										<div class="${styles.Animator}">${`${index + 1}`.padStart(2, '0')}</div>
									</div>
									<canvas class="${styles.Canvas}"></canvas>
									<div class="${styles.Title}"><div class="${styles.Animator}">${title}</div></div>
									<div class="${styles.Subtitle}">
										<div class="${styles.Animator}">${subtitle}</div>
									</div>
									<div class="${clsx(styles.Text, typoStyles.Text)}">
										<div class="${styles.Animator}">
											${asHTML(text)}
											<div></div>
										</div>
									</div>
								</div>`
							)
							.join('')}
				  </div>`
				: ''}
		</div>
	</div>`
}
