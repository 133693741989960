import styles from './Text.module.css'
import nodeStyles from '../node/Node.module.css'
import typoStyles from '../Typography.module.css'
import { PageDocumentDataBodyTextSlice } from '../../@types/types.generated'
import { asHTML, asLink, Element, HTMLFunctionSerializer } from '@prismicio/helpers'
import clsx from 'clsx'
import { FilledLinkToWebField, LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import { Arrow } from '../Arrow'
import linkResolver from '../../lib/linkResolver'

const Icon = (flipped?: boolean) => `<span class="${styles.Icon}"><span>${Arrow(flipped)}</span></span>`

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.hyperlink) {
		const { data } = node as RTLinkNode

		switch (data.link_type) {
			case LinkType.Web: {
				const { url, target } = data as FilledLinkToWebField
				const noArrow = url.includes('mailto:') || url.includes('tel:')
				return /* HTML */ `<a
					href="${url}"
					${target ? `target="${target}"` : ''}
					rel="noopener noreferrer"
					class="${styles.Link}"
					><span>${text}</span>${noArrow ? '' : Icon()}</a
				>`
			}

			case LinkType.Document: {
				return `<a href="${asLink(data, linkResolver)}" class="${
					styles.Link
				}"><span>${text}</span>${Icon()}</a>`
			}

			default: {
				return null
			}
		}
	}

	return null
}

export const Text = (props: PageDocumentDataBodyTextSlice): string => {
	const {
		primary: { text, sidebar }
	} = props
	return /* HTML */ `<div class="${clsx(styles.Main, nodeStyles.Main)}" data-visible="${styles.Visible}">
		${sidebar
			? `<div class="${clsx(typoStyles.Text, styles.Sidebar)}">${asHTML(sidebar, linkResolver, serializer)}</div>`
			: ''}
		${text ? `<div class="${clsx(typoStyles.Text, styles.Text)}">${asHTML(text, linkResolver)}</div>` : ''}
	</div>`
}
