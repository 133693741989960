import {
	Mesh,
	MeshBasicMaterial,
	OrthographicCamera,
	PlaneGeometry,
	Scene,
	ShaderMaterial,
	Vector2,
	WebGLRenderTarget
} from 'three'
import { ProductsController } from './ProductsController'
import vertexShader from './blur.vert'
import fragmentShader from './blur.frag'

const SIZE = 512
const PLANE_WIDTH = 5
const BACKGROUND_BLUR = 0.015

export class Shadow {
	private readonly shadowCamera: OrthographicCamera
	private readonly whiteMaterial: MeshBasicMaterial
	private readonly plane: Mesh<PlaneGeometry, MeshBasicMaterial>
	private readonly scene: Scene
	private readonly mesh: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly renderTarget1: WebGLRenderTarget
	private readonly renderTarget2: WebGLRenderTarget
	private readonly renderTarget3: WebGLRenderTarget
	private readonly blackMaterial: MeshBasicMaterial
	private readonly planeMaterial: MeshBasicMaterial

	constructor(private readonly controller: ProductsController) {
		this.scene = new Scene()
		this.renderTarget1 = new WebGLRenderTarget(SIZE, SIZE)
		this.renderTarget1.texture.generateMipmaps = false
		this.renderTarget2 = new WebGLRenderTarget(SIZE, SIZE)
		this.renderTarget2.texture.generateMipmaps = false
		this.renderTarget3 = new WebGLRenderTarget(SIZE, SIZE)
		this.renderTarget3.texture.generateMipmaps = false
		this.whiteMaterial = new MeshBasicMaterial({ color: 0xffffff })
		this.blackMaterial = new MeshBasicMaterial({ color: 0x000000 })

		this.mesh = new Mesh(
			new PlaneGeometry(2, 2),
			new ShaderMaterial({
				vertexShader,
				fragmentShader,
				depthWrite: false,
				depthTest: false,
				uniforms: {
					map: { value: null },
					delta: { value: new Vector2(BACKGROUND_BLUR, 0) },
					resolution: { value: new Vector2(SIZE, SIZE) }
				}
			})
		)

		this.shadowCamera = new OrthographicCamera(
			-PLANE_WIDTH * 0.5,
			PLANE_WIDTH * 0.5,
			PLANE_WIDTH * 0.5,
			-PLANE_WIDTH * 0.5,
			0,
			10
		)
		this.shadowCamera.rotation.x = Math.PI * -0.5

		const planeGeometry = new PlaneGeometry(PLANE_WIDTH, PLANE_WIDTH, 1, 1)
		this.planeMaterial = new MeshBasicMaterial({
			color: 0x000000,
			alphaMap: this.renderTarget3.texture,
			transparent: true,
			opacity: 0.15
		})
		this.plane = new Mesh(planeGeometry, this.planeMaterial)
		this.plane.position.y = -0.5
		this.plane.rotation.x = Math.PI * -0.5

		this.scene.add(this.mesh)
		this.controller.scene.add(this.shadowCamera)
		this.controller.scene.add(this.plane)
	}

	dispose() {
		this.renderTarget1.dispose()
		this.renderTarget2.dispose()
		this.renderTarget3.dispose()

		this.whiteMaterial.dispose()
		this.blackMaterial.dispose()

		this.scene.remove(this.mesh)
	}

	update() {
		this.controller.meshes.forEach((mesh) => (mesh.material = this.whiteMaterial))
		this.plane.material = this.blackMaterial

		this.controller.renderer.setRenderTarget(this.renderTarget1)
		this.controller.renderer.clear()
		this.controller.renderer.render(this.controller.scene, this.shadowCamera)

		this.plane.material = this.planeMaterial

		this.mesh.material.uniforms.delta.value.set(BACKGROUND_BLUR, 0)
		this.mesh.material.uniforms.map.value = this.renderTarget1.texture

		this.controller.renderer.setRenderTarget(this.renderTarget2)
		this.controller.renderer.clear()
		this.controller.renderer.render(this.scene, this.shadowCamera)

		this.mesh.material.uniforms.delta.value.set(0, BACKGROUND_BLUR)
		this.mesh.material.uniforms.map.value = this.renderTarget2.texture
		this.controller.renderer.setRenderTarget(this.renderTarget3)
		this.controller.renderer.clear()
		this.controller.renderer.render(this.scene, this.shadowCamera)
	}
}
