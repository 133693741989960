import styles from './Linklist.module.css'
import nodeStyles from '../node/Node.module.css'
import { FilledLinkToWebField, LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import { PageDocumentDataBodyLinklistSlice } from '../../@types/types.generated'
import { asHTML, asLink, Element, HTMLFunctionSerializer } from '@prismicio/helpers'
import linkResolver from '../../lib/linkResolver'
import { Arrow } from '../Arrow'
import clsx from 'clsx'

const Icon = (flipped?: boolean) => `<span class="${styles.Icon}"><span>${Arrow(flipped)}</span></span>`

const getSerializer = (index: number, anchor?: string | null) => {
	const serializer: HTMLFunctionSerializer = (
		type: string,
		node: RTAnyNode,
		text: string | undefined,
		children: string
	): string | null | undefined => {
		const style = `--delay: ${500 + index * 100}ms;`
		if (type === Element.hyperlink) {
			const { data } = node as RTLinkNode

			switch (data.link_type) {
				case LinkType.Web: {
					const { url, target } = data as FilledLinkToWebField
					return /* HTML */ `<a
						href="${url}"
						${target ? `target="${target}"` : ''}
						rel="noopener noreferrer"
						class="${styles.Link}"
						style="${style}"
						><span>${text}</span>${Icon()}</a
					>`
				}

				case LinkType.Document: {
					return /* HTML */ `<a
						href="${asLink(data, linkResolver)}${anchor ? `#${anchor}` : ''}"
						class="${styles.Link}"
						style="${style}"
						><span>${text}</span>${Icon()}</a
					>`
				}

				case LinkType.Media: {
					const { url } = data
					return /* HTML */ `<a href="${url}" class="${styles.Link}" style="${style}"
						><span>${text}</span>${Icon()}</a
					>`
				}
			}
		} else if (type === Element.paragraph && !!anchor) {
			const isAnchor = children.indexOf('<a href="') !== -1
			if (!isAnchor) {
				return /* HTML */ `<a href="#${anchor}" class="${styles.Link}" style="${style}"
					><span>${text}</span>${Icon(true)}</a
				>`
			}
			return null
		}

		return null
	}

	return serializer
}

export const Linklist = (props: PageDocumentDataBodyLinklistSlice): string => {
	const { items } = props
	return /* HTML */ `<div class="${clsx(styles.Main, nodeStyles.Main)}" data-visible="${styles.Visible}">
		${items.length > 0
			? `<div class="${styles.Links}">${items
					.map((item, index) => asHTML(item.link, linkResolver, getSerializer(index, item.anchor)))
					.join('')}</div>`
			: ''}
	</div>`
}
