import styles from './Grid.module.css'
import { Easing, Group, Tween } from '@tweenjs/tween.js'

export class GridController {
	private readonly items: HTMLElement[]
	private readonly group = new Group()
	private startPositions: DOMRect[] = []

	constructor(private readonly node: HTMLElement) {
		this.items = [
			...Array.from(node.querySelectorAll(`.${styles.Cross}`) as NodeListOf<HTMLElement>),
			...Array.from(node.querySelectorAll(`.${styles.Lines}`) as NodeListOf<HTMLElement>)
		]

		this.node.style.setProperty('opacity', '1')
	}

	setStartPositions() {
		this.startPositions = this.items.map((icon) => icon.getBoundingClientRect())
	}

	async animate(duration = 3000) {
		this.group.removeAll()
		return new Promise<void>((resolve) => {
			const endPositions = this.items.map((icon) => icon.getBoundingClientRect())

			this.items.forEach((node, index) => {
				const startX = this.startPositions[index]?.x || 0
				const startY = this.startPositions[index]?.y || 0

				const x = startX - endPositions[index].x
				const y = startY - endPositions[index].y

				node.style.setProperty('transform', `translate(${x}px,${y}px)`)

				new Tween({ x, y }, this.group)
					.to({ x: 0, y: 0 }, duration)
					.easing(Easing.Exponential.InOut)
					.onUpdate(({ x, y }) => node.style.setProperty('transform', `translate(${x}px,${y}px)`))
					.onComplete(() => {
						node.style.removeProperty('transform')
						resolve()
					})
					.start()

				new Tween({ flicker: 1 + Math.random() }, this.group)
					.to({ flicker: 0 }, duration * 0.333)
					.delay(duration * 0.333)
					.easing(Easing.Sinusoidal.In)
					.onUpdate(({ flicker }) => {
						const opacity = 1 - (1 + Math.sin(flicker * 1000)) * 0.5
						node.style.setProperty('opacity', `${opacity}`)
					})
					.onComplete(() => node.style.removeProperty('opacity'))
					.start()
			})
		})
	}

	dispose(): void {
		this.group.removeAll()
	}

	update(time: number) {
		this.group.update(time)
	}
}
