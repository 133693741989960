import styles from './Spacer.module.css'
import { PageDocumentDataBodySpacerSlice } from '../@types/types.generated'
import clsx from 'clsx'

export function Spacer(props: PageDocumentDataBodySpacerSlice): string {
	const {
		primary: { size }
	} = props

	return /* HTML */ ` <div class="${clsx(styles.Main, size === 'small' && styles.Small)}"></div>`
}
