import styles from './Navigation.module.css'
import { Lang } from '../../lib/primsic'
import { asLink, isFilled } from '@prismicio/helpers'
import linkResolver from '../../lib/linkResolver'
import { AlternateLanguage } from '@prismicio/types'
import { resolver } from '../../lib/resolver'
import { Menu } from './Menu'
import { NavigationGrid } from './NavigationGrid'
import { NavigationDocument, PageDocument } from '../../@types/types.generated'

export enum LangLabel {
	'de' = 'Deutsch',
	'fr' = 'Français',
	'it' = 'Italiano',
	'en' = 'english'
}

type Props = {
	navigation: NavigationDocument<Lang>
	route: string
	lang: Lang
	alternate_languages: AlternateLanguage<string, Lang>[]
}

export function Navigation(props: Props): string {
	const {
		navigation: {
			data: { primary_links, secondary_links, loading_text }
		},
		alternate_languages,
		lang,
		route
	} = props

	const primaryLinks = primary_links.map((item) => ({
		href: isFilled.contentRelationship<'page', Lang, PageDocument['data']>(item.page)
			? asLink(item.page, linkResolver) || undefined
			: undefined,
		label: isFilled.contentRelationship<'page', Lang, PageDocument['data']>(item.page)
			? item.page.data?.page_title || undefined
			: undefined
	}))

	const secondaryLinks = secondary_links.map((item) => ({
		href: isFilled.contentRelationship<'page', Lang, PageDocument['data']>(item.page)
			? asLink(item.page, linkResolver) || undefined
			: undefined,
		label: isFilled.contentRelationship<'page', Lang, PageDocument['data']>(item.page)
			? item.page.data?.page_title || undefined
			: undefined
	}))

	const langLinks = alternate_languages.map((alternate) => ({
		href: alternate.uid ? resolver(alternate.uid, alternate.type, alternate.lang) : `/${alternate.lang}`,
		label: LangLabel[alternate.lang]
	}))

	langLinks.unshift({
		href: route,
		label: LangLabel[lang]
	})

	return /* HTML */ `
		<header class="${styles.Main}">
			<div class="${styles.Bg}"></div>
			${NavigationGrid({ lang, loading_text: loading_text || undefined })}
			${Menu({ primary: primaryLinks, secondary: secondaryLinks, route })}
			${Menu({ primary: langLinks, route, bottom: true })}
		</header>
	`
}
