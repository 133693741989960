import { AlternateLanguage } from '@prismicio/types'

import { domain, repo_name } from '../../config.json'
import font1 from '../assets/HelveticaNeue-Medium.woff2'
import font2 from '../assets/source-serif-4-v7-latin-300.woff2'
import font3 from '../assets/source-serif-4-v7-latin-300italic.woff2'
import font4 from '../assets/FTSystemMono-Regular.woff2'
import { Lang } from '../lib/primsic'
import clsx from 'clsx'
import styles from './Html.module.css'
import { resolver } from '../lib/resolver'
import favicon from '../assets/favicon.png'
import ogImage from '../assets/OpenGraphImage.png'
import { Cookie } from './cookie/Cookie'
import { CookieDocument } from '../@types/types.generated'

export type Props = {
	lang: Lang
	routes: string[]
	cookie?: CookieDocument<Lang>
	page_title?: string
	alternate_languages?: AlternateLanguage<string, Lang>[]
	children?: string
	fixed?: boolean
	dev?: boolean
}

export function Html(props: Props): string {
	const {
		lang,
		routes,
		cookie,
		page_title = 'Setaprint',
		alternate_languages = [],
		children = '',
		fixed = false,
		dev = false
	} = props
	//
	return /* HTML */ `
		<!DOCTYPE html>
		<html lang="${lang}">
			<head>
				<meta charset="utf-8" />
				${alternate_languages
					.map(({ uid, type, lang }) =>
						uid && type && lang
							? /* HTML */ `<link
									rel="alternate"
									hreflang="${lang}"
									href="${domain}${resolver(uid, type, lang)}/"
							  />`
							: ''
					)
					.join('')}
				<title>${page_title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta property="og:image" content="${domain}${ogImage}" />
				<link rel="shortcut icon" type="image/png" href="${favicon}" />
				<link rel="preload" href="${font1}" as="font" crossorigin="anonymous" />
				<link rel="preload" href="${font2}" as="font" crossorigin="anonymous" />
				<link rel="preload" href="${font3}" as="font" crossorigin="anonymous" />
				<link rel="preload" href="${font4}" as="font" crossorigin="anonymous" />
				${routes.map((route) => `<link rel="prefetch" href="${route}/index.html"/>`).join('')}
				<script>
					window.__ROUTES__ = ${JSON.stringify(routes)}
				</script>
				<script>
					window.dataLayer = window.dataLayer || []
					function gtag() {
						dataLayer.push(arguments)
					}
					gtag('consent', 'default', {
						analytics_storage: 'denied'
					})
				</script>
				<script>
					;(function (w, d, s, l, i) {
						w[l] = w[l] || []
						w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
						var f = d.getElementsByTagName(s)[0],
							j = d.createElement(s),
							dl = l != 'dataLayer' ? '&l=' + l : ''
						j.async = true
						j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
						f.parentNode.insertBefore(j, f)
					})(window, document, 'script', 'dataLayer', 'GTM-5BVN96J')
				</script>
				<script>
					function consentGranted() {
						gtag('consent', 'update', {
							analytics_storage: 'granted'
						})
					}
				</script>

				<script async defer src="/index.js"></script>
				<script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=${repo_name}"></script>
				<link rel="stylesheet" type="text/css" href="/index.css" />

				${dev
					? /* HTML */ `<script>
							new EventSource('/esbuild').addEventListener('change', () => location.reload())
					  </script>`
					: ''}
			</head>
			<body class="${clsx(styles.Body, fixed && styles.IsFixed)}">
				<div class="${styles.Stage}"></div>
				${children} ${Cookie(cookie)}
				<style>
					@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=9975a852-fb7a-4442-b5b2-eff8203abed1');
				</style>
				<noscript
					><iframe
						src="https://www.googletagmanager.com/ns.html?id=GTM-5BVN96J"
						height="0"
						width="0"
						style="display:none;visibility:hidden"
					></iframe
				></noscript>
				<script>
					window.va =
						window.va ||
						function () {
							;(window.vaq = window.vaq || []).push(arguments)
						}
				</script>
			</body>
		</html>
	`
}
