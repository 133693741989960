import styles from './FluidTitle.module.css'
import { PageDocumentDataBodyFluidTitleSlice } from '../../@types/types.generated'
import { asHTML, isFilled } from '@prismicio/helpers'
import linkResolver from '../../lib/linkResolver'
import clsx from 'clsx'

export const FluidTitle = (props: PageDocumentDataBodyFluidTitleSlice): string => {
	const {
		primary: { title, link, icon, narrow }
	} = props

	return /* HTML */ `<div class="${clsx(styles.Main, narrow && styles.Narrow)}">
		<h2 class="${styles.Title}">${title}</h2>
		${isFilled.richText(link)
			? `<span class="${styles.Link}" data-icon="${icon}">${asHTML(link, linkResolver)}</span>`
			: ''}
	</div>`
}
