import styles from './LargeText.module.css'
import { PageDocumentDataBodyLargeTextSlice } from '../@types/types.generated'
import { asHTML } from '@prismicio/helpers'
import linkResolver from '../lib/linkResolver'

export const LargeText = (props: PageDocumentDataBodyLargeTextSlice): string => {
	const {
		primary: { text }
	} = props
	return /* HTML */ `<div class="${styles.Main}">${asHTML(text, linkResolver)}</div>`
}
