import styles from './Anchor.module.css'
import { PageDocumentDataBodyAnchorSlice } from '../../@types/types.generated'

export function Anchor(props: PageDocumentDataBodyAnchorSlice): string {
	const {
		primary: { label }
	} = props

	return /* HTML */ `<div class="${styles.Main}" data-id="${label}"></div>`
}
