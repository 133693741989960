import styles from './Navigation.module.css'
import menuStyles from './Menu.module.css'
import gridStyles from './NavigationGrid.module.css'
import { App, Controller } from '../App'
import { NavigationGridController } from './NavigationGridController'
import { MenuController } from './MenuController'
import { delay } from '../../utils/delay'
import { Group } from '@tweenjs/tween.js'

export class NavigationController implements Controller {
	private readonly node: HTMLElement
	private readonly app: App
	private readonly grid: NavigationGridController
	private mainMenu: MenuController
	private langMenu: MenuController
	private bg: HTMLElement
	public readonly group = new Group()

	constructor(node: HTMLElement, app: App, animate = false, private readonly intro = false) {
		this.app = app
		this.node = node

		const menus = Array.from(node.querySelectorAll(`.${menuStyles.Main}`) as NodeListOf<HTMLElement>)

		this.grid = new NavigationGridController(
			node.querySelector(`.${gridStyles.Main}`) as HTMLElement,
			this,
			animate
		)
		this.mainMenu = new MenuController(menus[0], this)
		this.langMenu = new MenuController(menus[1], this)

		this.bg = node.querySelector(`.${styles.Bg}`) as HTMLElement
	}

	openMenu(type: 'main' | 'lang') {
		this.node.classList.add(styles.Open)
		this.bg.classList.add(styles.Visible)
		type === 'main' ? this.mainMenu.open() : this.langMenu.open()
	}

	closeMenu() {
		this.node.classList.remove(styles.Open)
		this.bg.classList.remove(styles.Visible)
		this.grid.onMenuClose()
		this.mainMenu.close()
		this.langMenu.close()
	}

	async show(animate = false) {
		await this.grid.show(animate)
		this.mainMenu.show()
		this.langMenu.show()
	}

	async hide() {
		this.grid.hide()
		this.mainMenu.hide()
		this.langMenu.hide()
		await delay(500)
		this.bg.classList.remove(styles.Visible)
	}

	dispose(): void {
		this.langMenu.dispose()
		this.grid.dispose()
	}

	update(time: number) {
		this.group.update(time)

		this.grid.update(time)

		if (this.intro) {
			const { scrollY, windowHeight, bodyHeight } = this.app
			const top = Math.min(scrollY / (windowHeight * 0.5), 1)
			const bottom = Math.min(((scrollY - bodyHeight + windowHeight) / windowHeight) * -1, 1)
			this.node.style.setProperty('opacity', `${1 - top * bottom}`)
		}
	}
}
