import styles from './Gallery.module.css'
import { App, Controller } from '../App'

export class GalleryController implements Controller {
	private readonly filters: HTMLElement[]
	private readonly items: HTMLElement[]

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onIntersect = this.onIntersect.bind(this)
		this.onFilterClick = this.onFilterClick.bind(this)
		this.filters = Array.from(this.node.querySelectorAll(`.${styles.Filter}`) as NodeListOf<HTMLElement>)
		this.items = Array.from(this.node.querySelectorAll(`.${styles.Item}`) as NodeListOf<HTMLElement>)

		this.filters.forEach((filter) => filter.addEventListener('click', this.onFilterClick))
	}

	async show() {
		this.app.on('intersect', this.onIntersect)
		this.items.forEach((item) => this.app.intersectionObserver.observe(item))
	}

	onFilterClick(event: MouseEvent) {
		const id = (event.currentTarget as HTMLElement)?.dataset.filter?.trim()

		this.filters.forEach((filter) =>
			filter.dataset.filter?.trim() === id
				? filter.classList.add(styles.Active)
				: filter.classList.remove(styles.Active)
		)

		this.items.forEach((item) => {
			!id ||
			item.dataset.filters
				?.split(',')
				.map((filter) => filter.trim())
				?.includes(id)
				? item.classList.remove(styles.Hidden)
				: item.classList.add(styles.Hidden)
		})
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			this.items.forEach((item) => {
				if (entry.target === item) {
					entry.isIntersecting ? item.classList.add(styles.Visible) : item.classList.remove(styles.Visible)
				}
			})
		})
	}

	dispose() {
		this.filters.forEach((filter) => filter.removeEventListener('click', this.onFilterClick))
		this.app.removeListener('intersect', this.onIntersect)
		this.items.forEach((item) => this.app.intersectionObserver.unobserve(item))
	}
}
