import { Client } from '@prismicio/client'
import { CookieDocument, NavigationDocument, PageDocument, RedirectDocument } from '../@types/types.generated'

export enum Lang {
	'de' = 'de',
	'fr' = 'fr',
	'it' = 'it',
	'en' = 'en'
}

export async function getData(
	client: Client,
	ref?: string
): Promise<{
	allPages: PageDocument<Lang>[]
	allNavigations: NavigationDocument<Lang>[]
	allCookies: CookieDocument<Lang>[]
	allRedirects: RedirectDocument<Lang>[]
}> {
	const [allPages, allNavigations, allCookies, allRedirects] = await Promise.all([
		client.getAllByType<PageDocument<Lang>>('page', {
			ref,
			lang: '*'
		}),
		client.getAllByType<NavigationDocument<Lang>>('navigation', {
			ref,
			lang: '*',
			fetchLinks: ['page.page_title']
		}),
		client.getAllByType<CookieDocument<Lang>>('cookie', {
			ref,
			lang: '*'
		}),
		client.getAllByType<RedirectDocument<Lang>>('redirect', {
			ref,
			lang: '*'
		})
	])

	return {
		allPages,
		allNavigations,
		allCookies,
		allRedirects
	}
}
