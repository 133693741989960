import { Color, Group, Mesh, Object3D, PlaneGeometry, ShaderMaterial, Texture, Vector2 } from 'three'
import { Stage } from '../Stage'

import vertexShader from './label.vert'
import fragmentShader from './label.frag'
import iconFragmentShader from './icon.frag'
import { SDFGeometry } from '../sdf/SDFGeometry'
import { COLORS, FONT_SCALE } from '../../../const'
import { PageDocumentDataBodyFluidTitleSlice } from '../../@types/types.generated'
import { RAY_LAYER } from '../RayCaster'
import { Easing, Tween } from '@tweenjs/tween.js'
import { getTextGeometry } from '../textGeometry/getTextGeometry'

const SCALE_LANDSCAPE = 0.5
const SCALE_PORTRAIT = 1.25
const ICON_SIZE = 0.085

export type IconType = PageDocumentDataBodyFluidTitleSlice['primary']['icon']

const UV_OFFSET: { [key in IconType]: Vector2 } = {
	none: new Vector2(),
	dots: new Vector2(0.5, 0.75),
	close: new Vector2(0.75, 0.75)
}

export class FluidButton {
	private readonly object = new Group()
	private readonly link: string | null
	private readonly textMaterial: ShaderMaterial
	private readonly text: string
	private readonly iconType: IconType
	private mesh?: Mesh<SDFGeometry, ShaderMaterial>
	private icon?: Mesh<PlaneGeometry, ShaderMaterial>
	private tween?: Tween<{ elapsed: number }>
	private hoverElapsed = 0
	private hovered = false
	private initialized = false
	private parentScale = 1

	constructor(private readonly stage: Stage, parent: Object3D, anchor: HTMLAnchorElement) {
		this.text = anchor?.textContent || ''
		this.iconType = anchor.dataset.icon as IconType
		const href = anchor.querySelector('a')?.href || null
		this.link = href ? new URL(href).pathname : null

		this.textMaterial = new ShaderMaterial({
			transparent: true,
			vertexShader,
			fragmentShader,
			depthTest: false,
			uniforms: {
				offset: { value: 0 },
				time: { value: 0 },
				left: { value: 0 },
				width: { value: 0 },
				opacity: { value: 1 },
				resolution: { value: new Vector2() },
				map: { value: null },
				sdf: { value: this.stage.sdfTextures.get('italic') },
				color: { value: new Color(COLORS.grey01) }
			}
		})

		this.object.scale.set(SCALE_LANDSCAPE, SCALE_LANDSCAPE, SCALE_LANDSCAPE)
		parent.add(this.object)
	}

	async init() {
		if (this.initialized) return

		const { geometry, size } = await getTextGeometry(
			this.text,
			'italic',
			FONT_SCALE,
			this.stage.sdfFonts.get('italic'),
			true
		)

		this.mesh = new Mesh(geometry, this.textMaterial)
		this.mesh.userData.href = this.link
		this.textMaterial.uniforms.width.value = size.x

		this.object.add(this.mesh)

		if (this.iconType !== 'none') {
			const iconMaterial = new ShaderMaterial({
				transparent: true,
				vertexShader,
				fragmentShader: iconFragmentShader,
				depthTest: false,
				uniforms: {
					uvOffset: { value: UV_OFFSET[this.iconType] },
					offset: { value: 0 },
					time: { value: 0 },
					left: { value: 0 },
					width: { value: ICON_SIZE * 4 },
					opacity: { value: 1 },
					resolution: { value: new Vector2() },
					map: { value: null },
					iconMap: { value: this.stage.iconsTexture },
					color: { value: new Color(COLORS.grey01) }
				}
			})

			this.icon = new Mesh(new PlaneGeometry(ICON_SIZE, ICON_SIZE, 7, 7), iconMaterial)
			this.icon.position.x = size.x * 0.5 + ICON_SIZE * 0.5 + 0.025
			this.icon.position.y = 0.01
			this.icon.userData.href = this.link

			this.object.add(this.icon)
			this.object.position.x = ICON_SIZE * -0.5 * SCALE_LANDSCAPE
		}

		this.initialized = true
	}

	setActive() {
		this.mesh?.layers.enable(RAY_LAYER)
		this.icon?.layers.enable(RAY_LAYER)
	}

	unsetActive() {
		this.mesh?.layers.disable(RAY_LAYER)
		this.icon?.layers.disable(RAY_LAYER)
	}

	setHover(hover: string | null) {
		const hovered = this.link !== null && hover === this.link

		if (!this.hovered && hovered) {
			this.hovered = true

			this.tween?.stop()
			this.tween = new Tween({ elapsed: this.hoverElapsed }, this.stage.group)
				.to({ elapsed: 1 }, 250)
				.easing(Easing.Cubic.InOut)
				.onUpdate(({ elapsed }) => (this.hoverElapsed = elapsed))
				.start()
		} else if (this.hovered && !hovered) {
			this.hovered = false

			this.tween?.stop()
			this.tween = new Tween({ elapsed: this.hoverElapsed }, this.stage.group)
				.to({ elapsed: 0 }, 250)
				.easing(Easing.Cubic.InOut)
				.onUpdate(({ elapsed }) => (this.hoverElapsed = elapsed))
				.start()
		}
	}

	setPositionY(y: number) {
		this.object.position.y = y
	}

	resize(parentScale: number) {
		if (!this.initialized) return

		const { width, height, dpr } = this.stage
		this.parentScale = parentScale

		if (this.mesh) {
			this.mesh.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		}

		if (this.icon) {
			this.icon.material.uniforms.resolution.value.set(width * dpr, height * dpr)
		}
	}

	update(time: number, map: Texture | null, ratio: number) {
		if (!this.initialized) return
		const { isDesktop } = this.stage

		ratio > -0.1 && ratio < 0.1 ? this.setActive() : this.unsetActive()
		const opacity = 1 - this.hoverElapsed * 0.8
		const baseScale = isDesktop ? SCALE_LANDSCAPE : SCALE_PORTRAIT
		const scale = (baseScale * (1 - this.hoverElapsed * 0.05)) / this.parentScale

		this.object.scale.set(scale, scale, scale)

		if (this.mesh) {
			this.mesh.material.uniforms.offset.value = ratio
			this.mesh.material.uniforms.time.value = time
			this.mesh.material.uniforms.map.value = map
			this.mesh.material.uniforms.opacity.value = opacity
		}

		if (this.icon) {
			this.icon.material.uniforms.offset.value = ratio
			this.icon.material.uniforms.time.value = time
			this.icon.material.uniforms.map.value = map
			this.icon.material.uniforms.opacity.value = opacity
		}
	}
}
