import styles from './Menu.module.css'
import clsx from 'clsx'
import { Button } from './Button'

type Item = { href?: string; label?: string }

type Props = {
	route: string
	primary: Item[]
	secondary?: Item[]
	bottom?: boolean
}

export function Menu(props: Props): string {
	const { route, primary, secondary, bottom = false } = props

	return /* HTML */ `
		<div class="${clsx(styles.Main, bottom && styles.Bottom)}">
			<div class="${styles.Close}">${Button('close')}</div>
			<div class="${styles.Inner}">
				${primary
					? /* HTML */ `<div class="${styles.Primary}">
							${primary
								?.map(
									(item) =>
										`<div class="${clsx(
											styles.Item,
											item.href === route && styles.Active
										)}" >${Button(item.label || '', item.href)}</div>`
								)
								.join('')}
					  </div>`
					: ''}
				${secondary
					? /* HTML */ `<div class="${styles.Secondary}">
							${secondary
								?.map(
									(item) =>
										`<div class="${clsx(
											styles.Item,
											item.href === route && styles.Active
										)}" >${Button(item.label || '', item.href)}</div>`
								)
								.join('')}
							<div class="${clsx(styles.Date, styles.DateDesktop)}">${Button('')}</div>
					  </div>`
					: ''}
			</div>
		</div>
	`
}
