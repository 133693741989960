import styles from './Machines.module.css'
import { PageDocumentDataBodyMachinesSlice, PageDocumentDataBodyMachinesSliceItem } from '../../@types/types.generated'
import { Arrow } from '../Arrow'
import { asHTML, Element, HTMLFunctionSerializer, isFilled } from '@prismicio/helpers'
import { Grid } from '../grid/Grid'
import linkResolver from '../../lib/linkResolver'
import { RTAnyNode } from '@prismicio/types'

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.heading4) {
		return `<h4><span>${text}</span></h4>`
	} else if (type === Element.paragraph) {
		return `<p><span>${text}</span></p>`
	}

	return null
}

export const Machines = (props: PageDocumentDataBodyMachinesSlice): string => {
	const {
		primary: { title, subtitle },
		items
	} = props

	const Infos = (infos: PageDocumentDataBodyMachinesSliceItem['infos']): string => {
		let html = asHTML(infos, linkResolver, serializer)

		const headings = html.match(/<h(.)>.*?<\/h\1>/g) || []
		const paragraphs = html.match(/<p>.*?<\/p>/g) || []

		return `<div class="${styles.Info}">${headings
			.map(
				(heading, index) =>
					/* HTML */ `<div style="--delay:${index * 150}ms;">${heading}${paragraphs[index]}</div>`
			)
			.join('')}</div> `
	}

	return /* HTML */ `<div class="${styles.Main}">
		<h2 class="${styles.Title}">${title}</h2>
		<div class="${styles.Subtitle}">${subtitle}</div>

		<div class="${styles.Links}">
			${items
				.map(
					(item, index) => /* HTML */ `<button
						class="${styles.Link}"
						data-index="${index}"
						data-file="${isFilled.linkToMedia(item.file) ? item.file.url : ''}"
						data-texture="${item.texture?.url}"
						data-shadow="${item.shadow?.url}"
					>
						<span class="${styles.Icon}"><span> ${Arrow(true)}</span></span
						><span class="${styles.Label}">${item.name}</span>
					</button>`
				)
				.join('')}
		</div>
		<div class="${styles.Container}">
			<div class="${styles.Inner}">
				<canvas class="${styles.Canvas}"></canvas>
			</div>
			<div class="${styles.Wrapper}">
				<div class="${styles.Grid}">
					${Grid()}
					<div class="${styles.ExtraGrid}">
						<div class="${styles.Color}">${new Array(7).fill('<div></div>').join('')}</div>
						<div class="${styles.Text}">machine_3D-visual</div>
					</div>
				</div>
			</div>
		</div>

		<div class="${styles.Infos}">${items.map((item) => Infos(item.infos)).join('')}</div>
	</div>`
}
