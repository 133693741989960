{"name":"main","layers":[{"name":"panel_1","index":10,"type":"vector","out":3033,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":1000,"v":[1900,1200]},{"t":1500,"v":[2000,1200]}]}],"fill":{"color":[{"t":0,"v":[202,202,202]}]},"transform":{

}}],"transform":{"anchor":[{"t":0,"v":[0,-600]}],"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":1000},{"t":1000,"v":0},{"t":1100,"v":-100}],"opacity":[{"t":2333.33333333333,"v":1},{"t":3000,"v":0}]}},{"name":"text_1","index":9,"type":"vector","out":3733,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":2333.33333333333,"v":[1750,600]},{"t":3000,"v":[800,600]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"anchor":[{"t":0,"v":[0,-300]}],"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":1100},{"t":1000,"v":100,"easeOut":[0.167,0]},{"t":2333.33333333333,"v":100.01,"easeIn":[0.833,1]},{"t":3000,"v":140}],"scaleX":[{"t":2333.33333333333,"v":1},{"t":3000,"v":0.6}],"scaleY":[{"t":2333.33333333333,"v":1},{"t":3000,"v":1}],"opacity":[{"t":3333.33333333333,"v":1},{"t":3666.66666666667,"v":0}]}},{"name":"text_2","index":8,"type":"vector","out":3733,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":2333.33333333333,"v":[1750,600]},{"t":3000,"v":[800,600]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"anchor":[{"t":0,"v":[0,-300]}],"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":500}],"scaleX":[{"t":2333.33333333333,"v":1},{"t":3000,"v":0.6}],"scaleY":[{"t":2333.33333333333,"v":1},{"t":3000,"v":1}],"opacity":[{"t":1333.33333333333,"v":0},{"t":1666.66666666667,"v":1},{"t":3333.33333333333,"v":1},{"t":3666.66666666667,"v":0}]}},{"name":"grid_1","index":7,"type":"vector","in":2333,"out":12367,"effects":{"sliderControl":{"slider":[{"t":6666.66666666667,"v":0},{"t":7000,"v":1}]}},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":2333.33333333333,"v":[2000,1000]},{"t":3000,"v":[800,600]},{"t":3666.66666666667,"v":[800,600]},{"t":4666.66666666667,"v":[1600,300]}]}],"fill":{"color":[{"t":0,"v":[255,81,81]}]},"transform":{

}}],"transform":{"anchor":[{"t":0,"v":[0,-500]}],"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":0}],"scaleX":[{"t":8333.33333333333,"v":1},{"t":12000,"v":0.1}],"scaleY":[{"t":8333.33333333333,"v":1},{"t":12000,"v":0.1}],"opacity":[{"t":2333.33333333333,"v":0},{"t":3000,"v":1},{"t":11000,"v":1},{"t":12000,"v":0}]}},{"name":"text_3","index":6,"type":"vector","in":4333,"out":5367,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[1800,200]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":500}],"opacity":[{"t":4333.33333333333,"v":0},{"t":4666.66666666667,"v":1},{"t":5000,"v":1},{"t":5333.33333333333,"v":0}]}},{"name":"text_4","index":5,"type":"vector","in":5333,"out":6367,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[1800,200]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":500}],"opacity":[{"t":5333.33333333333,"v":0},{"t":5666.66666666667,"v":1},{"t":6000,"v":1},{"t":6333.33333333333,"v":0}]}},{"name":"text_5","index":4,"type":"vector","in":6333,"out":7667,"effects":{"sliderControl":{"slider":[{"t":6666.66666666667,"v":0},{"t":7000,"v":1}]}},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[1800,200]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":500}],"opacity":[{"t":6333.33333333333,"v":0},{"t":6666.66666666667,"v":1},{"t":7333.33333333333,"v":1},{"t":7666.66666666667,"v":0}]}},{"name":"text_6","index":3,"type":"vector","in":7667,"out":12333,"effects":{"sliderControl":{"slider":[{"t":0,"v":1}]}},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[800,600]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"positionX":[{"t":0,"v":1000}],"positionY":[{"t":0,"v":500}],"scaleX":[{"t":8333.33333333333,"v":1},{"t":12000,"v":0.1}],"scaleY":[{"t":8333.33333333333,"v":1},{"t":12000,"v":0.1}],"opacity":[{"t":7666.66666666667,"v":0},{"t":8033.33333333333,"v":1},{"t":11000,"v":1},{"t":12000,"v":0}]}},{"name":"panel_2","index":2,"type":"vector","in":12000,"out":14000,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[1900,900]}]}],"fill":{"color":[{"t":0,"v":[202,202,202]}]},"transform":{

}}],"transform":{"anchor":[{"t":0,"v":[0,-450]}],"positionX":[{"t":0,"v":1000}],"positionY":[{"t":12000,"v":1000},{"t":13000,"v":50},{"t":14000,"v":-1000}]}},{"name":"text_7","index":1,"type":"vector","in":12000,"out":14000,"effects":{

},"groups":[{"name":"Rectangle 1","type":"vector","effects":{

},"shapes":[{"type":"rect","size":[{"t":0,"v":[1750,200]}]}],"fill":{"color":[{"t":0,"v":[0,255,30]}]},"transform":{

}}],"transform":{"positionX":[{"t":0,"v":1000}],"positionY":[{"t":12000,"v":1500},{"t":13000,"v":500},{"t":14000,"v":-1000}],"opacity":[{"t":13000,"v":1},{"t":14000,"v":0}]}}],"comps":{

},"duration":14033.3333333333,"width":2000,"height":1000,"markers":[

]}