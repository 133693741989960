import { BufferGeometry, Mesh, MeshBasicMaterial, Object3D, SRGBColorSpace, TextureLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import { isMesh } from '../../utils/isMesh'
import { Easing, Tween } from '@tweenjs/tween.js'
import { MachinesController } from './MachinesController'

export class MachineController {
	private object = new Object3D()
	private loaded = false
	private tween: any

	constructor(
		public readonly node: HTMLElement,
		public readonly index: number,
		private readonly controller: MachinesController
	) {
		this.object.visible = false
		controller.scene.add(this.object)
	}

	async show() {
		if (!this.loaded) {
			this.loaded = true
			await this.load()
		}

		this.tween?.stop
		this.tween = new Tween({ scale: 0.5 }, this.controller.group)
			.to({ scale: 1 }, 1000)
			.easing(Easing.Cubic.Out)
			.onStart(() => (this.object.visible = true))
			.onUpdate(({ scale }) => this.object.scale.set(scale, scale, scale))
			.start()
	}

	hide() {
		this.object.visible = false
	}

	async load() {
		const file = this.node.dataset.file
		const textureSrc = this.node.dataset.texture
		const shadowSrc = this.node.dataset.shadow

		if (!file || !textureSrc || !shadowSrc) {
			console.error('file, texture, shadow is undefined')
			return
		}

		const [{ scene }, map, shadow] = await Promise.all([
			new GLTFLoader().loadAsync(file),
			new TextureLoader().loadAsync(`${textureSrc}&w=2048&q=80`),
			new TextureLoader().loadAsync(`${shadowSrc}&w=2048&q=80`)
		])

		map.flipY = false
		map.colorSpace = SRGBColorSpace

		shadow.flipY = false
		shadow.colorSpace = SRGBColorSpace

		scene.traverse((obj) => {
			if (isMesh(obj) && obj.name === 'mesh') {
				obj.material = new MeshBasicMaterial({ map })
			} else if (isMesh(obj) && obj.name === 'shadow') {
				obj.material = new MeshBasicMaterial({ map: shadow })
			}
		})

		this.object.add(scene)
	}

	dispose() {
		this.object.children.forEach((obj) => {
			if (isMesh(obj)) {
				const mesh = obj as Mesh<BufferGeometry, MeshBasicMaterial>
				mesh.material.map?.dispose()
				mesh.material.dispose()
				obj.geometry.dispose()
			}
		})
	}
}
