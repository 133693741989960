import styles from './Button.module.css'
import nodeStyles from '../node/Node.module.css'
import { PageDocumentDataBodyButtonSlice } from '../../@types/types.generated'
import { asHTML, Element, HTMLFunctionSerializer } from '@prismicio/helpers'
import clsx from 'clsx'
import { LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import linkResolver from '../../lib/linkResolver'

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.hyperlink) {
		const { data } = node as RTLinkNode

		switch (data.link_type) {
			case LinkType.Media: {
				const { url } = data
				return /* HTML */ `<a href="${url}" download="true" target="_blank">${text}</a>`
			}
			default: {
				return null
			}
		}
	}

	return null
}

export const Button = (props: PageDocumentDataBodyButtonSlice): string => {
	const {
		primary: { button }
	} = props

	return /* HTML */ `<div class="${clsx(styles.Main, nodeStyles.Main)}" data-visible="${styles.Visible}">
		<div class="${styles.LinesTop}"></div>
		<div class="${styles.LinesBottom}"></div>
		<div class="${styles.LinesLeft}"></div>
		<div class="${styles.LinesRight}"></div>
		<div class="${styles.Inner}">${asHTML(button, linkResolver, serializer)}</div>
		<div class="${styles.Label}">&lt;button type="button"&gt;</div>
	</div>`
}
