import cookie from 'js-cookie'

import { preview_document_id_cookie, repo_name } from '../../config.json'
import { getData, Lang } from '../lib/primsic'
import { Page } from './Page'
import { PageDocument } from '../@types/types.generated'
import * as prismic from '@prismicio/client'
import { routeResolver } from '../lib/routeResolver'
import { Html } from './Html'
import { CustomSlice, prepareSlices } from '../utils/prepareSlices'

const isPage = (doc: any): doc is PageDocument<Lang> => doc.type === 'page'

const endpoint = prismic.getRepositoryEndpoint(repo_name)
export const client = prismic.createClient(endpoint)

export async function getPreview(ref: string, route?: string): Promise<string> {
	const { allPages, allNavigations, allCookies } = await getData(client, ref)
	const allDocs = [...allPages, ...allNavigations]
	const routes = [...allPages.map((page) => `${routeResolver(page)}`), '/unpublished/']

	let doc

	if (route === '/unpublished/') {
		const id = cookie.get(preview_document_id_cookie)
		doc = allDocs.find((page) => page.id === id)
	} else {
		const routeArray = route ? route.split('/') : []
		const lang = Lang[routeArray[1] as Lang]
		const uid = routeArray[2] || 'index'
		doc = allDocs.find((page) => page.uid === uid && page.lang === lang)
	}

	if (doc && route) {
		if (isPage(doc)) {
			const page = doc
			const {
				lang,
				alternate_languages,
				data: { page_title, body }
			} = page

			const route = `${routeResolver(page)}`
			const navigation = allNavigations.find((header) => header.lang === lang)
			const intro = body.some((slice) => slice.slice_type === 'intro')
			const slices = prepareSlices(body as CustomSlice[])
			const cookie = allCookies.find((cookie) => cookie.lang === lang)

			return Html({
				lang,
				routes,
				alternate_languages,
				cookie,
				page_title: page_title || undefined,
				fixed: !!intro,
				dev: false,
				children: Page({ page, route, routes, navigation, slices })
			})
		}
	}

	return ''
}
