import { SDFGeometry } from '../sdf/SDFGeometry'
import { FontType } from '../Stage'
import { Box3, BufferAttribute, Vector3 } from 'three'

const geometries = new Map()
const box = new Box3()

export const getTextGeometry = async (text: string, type: FontType, scale: number, font?: JSON, center = false) => {
	const key = `${type}-${text}`
	let { geometry, size } = geometries.get(key) || { geometry: undefined, size: undefined }

	if (!geometry) {
		geometry = new SDFGeometry({
			font,
			text
		})
		geometry.scale(scale, scale * -1, scale)
		box.setFromBufferAttribute(geometry.getAttribute('position') as BufferAttribute)
		size = box.getSize(new Vector3())

		if (center) {
			geometry.translate(size.x * -0.5, 0, 0)
		}

		geometries.set(key, { geometry, size })
	}

	return { geometry, size }
}
