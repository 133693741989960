import styles from './Intro.module.css'
import { PageDocumentDataBodyIntroSlice } from '../../@types/types.generated'

export const Intro = (props: PageDocumentDataBodyIntroSlice): string => {
	const {
		primary: { text_1, text_2, text_3, text_4, text_5, text_6, text_7 }
	} = props

	const texts = encodeURI(JSON.stringify([text_1, text_2, text_3, text_4, text_5, text_6, text_7]))

	return /* HTML */ `<div class="${styles.Main}" data-texts="${texts}">
		<div>1 ${text_1}</div>
		<div>2 ${text_2}</div>
		<div>3 ${text_3}</div>
		<div>4 ${text_4}</div>
		<div>5 ${text_5}</div>
		<div>6 ${text_6}</div>
		<div>7 ${text_7}</div>
	</div>`
}
