import styles from './Cookie.module.css'
import clsx from 'clsx'
import { CookieDocument } from '../../@types/types.generated'
import { Lang } from '../../lib/primsic'
import { asHTML } from '@prismicio/helpers'
import linkResolver from '../../lib/linkResolver'

const Decline = /* HTML */ `
	<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 12L17.866 17.8667" />
		<path d="M22.1333 22.1328L27.9993 27.9995" />
		<path d="M28 12L22.134 17.8667" />
		<path d="M17.8691 22.1328L12.0032 27.9995" />
	</svg>
`

const Accept = /* HTML */ `<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
	<path d="M28.5 15.5L18.5 25.5L13 20" />
</svg> `

export const Cookie = (props?: CookieDocument<Lang>): string => {
	return /* HTML */ `<div class="${styles.Main}">
		${props?.data.text ? `<div class="${styles.Text}">${asHTML(props.data.text, linkResolver)}</div>` : ''}
		<button class="${clsx(styles.Button, styles.Decline)}">${Decline}</button>
		<button class="${clsx(styles.Button, styles.Accept)}">${Accept}</button>
	</div>`
}
