import styles from './Menu.module.css'
import buttonStyles from './Button.module.css'
import { delay } from '../../utils/delay'
import { NavigationController } from './NavigationController'
import { getDateString } from '../../utils/getDateString'
import { ButtonController } from './ButtonController'

export class MenuController {
	private readonly closeButton: ButtonController
	private readonly items: ButtonController[]
	private readonly date?: ButtonController

	constructor(private readonly node: HTMLElement, private readonly navigation: NavigationController) {
		this.open = this.open.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)

		this.closeButton = new ButtonController(
			this.node.querySelector(`.${styles.Close} .${buttonStyles.Main}`) as HTMLElement,
			navigation,
			this.onCloseClick
		)

		this.items = Array.from(
			this.node.querySelectorAll(`.${styles.Item} .${buttonStyles.Main}`) as NodeListOf<HTMLElement>
		).map((node) => new ButtonController(node, navigation))

		const date = this.node.querySelector(`.${styles.Date}`)

		if (date) {
			this.date = new ButtonController(date.querySelector(`.${buttonStyles.Main}`) as HTMLElement, navigation)
			this.date.setText(getDateString())
		}
	}

	onCloseClick() {
		this.navigation.closeMenu()
	}

	async show(animate = false) {
		if (animate) await delay(2000)
		this.date?.show()
		this.items.forEach((item, index) => item.show(index * 100))
	}

	hide() {
		this.closeButton.hide()
		this.date?.hide()
		this.items.forEach((item, index) => item.hide(index * 50))
	}

	async open() {
		this.closeButton.show(500)
		this.items.forEach((item, index) => item.show(500 + index * 100))
		this.node.classList.add(styles.Open)
	}

	async close() {
		this.node.classList.remove(styles.Open)
		this.items.forEach((item, index) => item.hide(index * 50))
	}

	dispose(): void {
		this.date?.dispose()
		this.closeButton.dispose()
	}
}
