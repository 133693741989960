import styles from './Gallery.module.css'
import { PageDocumentDataBodyGallerySlice } from '../../@types/types.generated'
import clsx from 'clsx'
import { asHTML, Element, HTMLFunctionSerializer, isFilled } from '@prismicio/helpers'
import { Arrow } from '../Arrow'
import { FilledLinkToWebField, LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import linkResolver from '../../lib/linkResolver'

const IMAGE_SIZES = [360, 480, 960, 1440]

function getSrcSet(url: string, sizes: number[]) {
	const { origin, pathname, searchParams } = new URL(url)

	searchParams.delete('w')
	searchParams.delete('h')

	const src = `${origin}${pathname}?${searchParams.toString()}`
	return sizes.map((size) => `${src}&w=${size} ${size}w`).join(', ')
}

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.hyperlink) {
		const { data } = node as RTLinkNode

		if (data.link_type === LinkType.Web) {
			const { url, target } = data as FilledLinkToWebField
			return /* HTML */ `<a href="${url}" ${target ? `target="${target}"` : ''} rel="noopener noreferrer"
				>${Arrow()}<span>${text}</span></a
			>`
		}

		return null
	}

	return null
}

export const Gallery = (props: PageDocumentDataBodyGallerySlice): string => {
	const {
		primary: { filter },
		items
	} = props

	const filters = filter?.split(',')

	return /* HTML */ `<div class="${clsx(styles.Main)}">
		${filters
			? `<div class="${styles.Filters}">${filters
					.map(
						(filter, index) => /* HTML */ `<button
							class="${clsx(styles.Filter, index === 0 && styles.Active)}"
							data-filter="${index === 0 ? '' : filter}"
						>
							<span>${filter}</span>${Arrow(true)}
						</button>`
					)
					.join('')}</div>`
			: ''}
		${items.length > 0
			? /* HTML */ `<div class="${styles.Grid}">
					${items
						.map(({ image, image: { alt, url }, name, info, links, filter }, index) => {
							// let linksHtml = asHTML(links, linkResolver, serializer)
							// linksHtml = linksHtml.replaceAll('@', '&#8203;@')

							return /* HTML */ `<div class="${styles.Item}" data-filters="${filter}">
								<div class="${styles.Image}" style="--delay: ${(index % 5) * 150}ms;">
									${isFilled.image(image)
										? `<img class="${styles.Img}" alt="${alt}" srcset="${
												url ? getSrcSet(url, IMAGE_SIZES) : ''
										  }" src="${url}" sizes=" (min-width: 1024px) 20vw, 50vw" loading="lazy"/>`
										: ''}
								</div>
								<div class="${styles.Name}">${name}</div>
								<div class="${styles.Info}">${asHTML(info)}</div>
								<div class="${styles.Links}">${asHTML(links, linkResolver, serializer)}</div>
							</div>`
						})
						.join('')}
			  </div>`
			: ''}
	</div>`
}
