import styles from './Machines.module.css'
import gridStyles from '../grid/Grid.module.css'
import { App, Controller } from '../App'
import { MathUtils, Object3D, PerspectiveCamera, Scene, Vector2, WebGLRenderer } from 'three'
import { Group } from '@tweenjs/tween.js'
import { MachineController } from './MachineController'
import { GridController } from '../grid/GridController'
import { delay } from '../../utils/delay'

const DELAY = 0.025

export class MachinesController implements Controller {
	private readonly grid: GridController
	private readonly gridNode: HTMLElement
	private readonly dolly = new Object3D()
	private readonly camera: PerspectiveCamera
	private readonly container: HTMLElement
	private readonly wrapper: HTMLElement
	private readonly renderer: WebGLRenderer
	private readonly links: HTMLButtonElement[]
	private readonly dpr: number
	private readonly text: HTMLElement
	private readonly color: HTMLElement
	private inView = false
	private machines: MachineController[]
	private infos: HTMLButtonElement[]
	private width = 0
	private height = 0
	private left = 0
	private top = 0
	private pointer = new Vector2()

	public readonly scene: Scene
	public readonly group = new Group()
	private activeIndex = 0

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onIntersect = this.onIntersect.bind(this)
		this.onClick = this.onClick.bind(this)

		this.dpr = Math.min(window.devicePixelRatio, 2)
		this.scene = new Scene()
		this.camera = new PerspectiveCamera()
		this.camera.position.y = 3
		this.camera.position.z = 14
		this.camera.lookAt(this.scene.position)

		this.dolly.position.y = 1
		this.dolly.add(this.camera)
		this.scene.add(this.dolly)

		this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement
		this.wrapper = this.node.querySelector(`.${styles.Wrapper}`) as HTMLElement
		const canvas = this.container.querySelector(`.${styles.Canvas}`) as HTMLCanvasElement
		this.links = Array.from(this.node.querySelectorAll(`.${styles.Link}`) as NodeListOf<HTMLButtonElement>)
		this.infos = Array.from(this.node.querySelectorAll(`.${styles.Info}`) as NodeListOf<HTMLButtonElement>)
		this.machines = this.links.map((link, index) => new MachineController(link, index, this))

		this.renderer = new WebGLRenderer({ antialias: true, canvas, alpha: true })
		this.renderer.setPixelRatio(this.dpr)

		this.links.forEach((link) => link.addEventListener('click', this.onClick))
		this.gridNode = this.node.querySelector(`.${styles.Grid}`) as HTMLElement
		this.grid = new GridController(this.node.querySelector(`.${gridStyles.Main}`) as HTMLElement)

		this.color = this.node.querySelector(`.${styles.Color}`) as HTMLElement
		this.text = this.node.querySelector(`.${styles.Text}`) as HTMLElement

		this.resize()
	}

	async show() {
		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	async setModel(index: number) {
		this.activeIndex = index
		this.gridNode.classList.remove(styles.Animate)
		this.gridNode.classList.add(styles.Centered)
		this.grid.setStartPositions()
		this.color.classList.remove(styles.Visible)
		this.text.classList.remove(styles.Visible)

		this.infos.forEach((info, i) =>
			index === i ? info.classList.add(styles.Visible) : info.classList.remove(styles.Visible)
		)

		this.links.forEach((link, i) =>
			index === i ? link.classList.add(styles.Active) : link.classList.remove(styles.Active)
		)

		await Promise.all([this.machines.map((machine) => (machine.index === index ? machine.show() : machine.hide()))])

		this.gridNode.classList.remove(styles.Centered)
		this.gridNode.classList.add(styles.Animate)
		this.grid.animate(2000)
		await delay(1250)

		this.color.classList.add(styles.Visible)
		this.text.classList.add(styles.Visible)
	}

	onClick(event: MouseEvent) {
		const index = parseInt((event.currentTarget as HTMLElement)?.dataset.index || '0', 10) || 0
		this.setModel(index)
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				this.inView = entry.isIntersecting
				if (this.inView && !this.activeIndex) {
					this.setModel(0)
				}
			}
		})
	}

	resize(): void {
		const { scrollY } = this.app
		const { width, height, top, left } = this.container.getBoundingClientRect()
		const { width: wrapperWidth } = this.wrapper.getBoundingClientRect()
		this.width = width
		this.height = height
		this.top = top + scrollY
		this.left = left

		this.camera.aspect = width / height
		this.camera.updateProjectionMatrix()
		this.renderer.setSize(width, height)

		const offsetX = width - wrapperWidth
		this.camera.setViewOffset(width, height, offsetX * 0.5, 0, width, height)
	}

	dispose() {
		this.links.forEach((link) => link.removeEventListener('click', this.onClick))
		this.machines.forEach((machine) => machine.dispose())
		this.group.removeAll()
		this.grid.dispose()
		this.renderer.dispose()
		this.scene.children.forEach((child) => this.scene.remove(child))
	}

	update(time: number) {
		if (!this.inView) return
		this.group.update(time)
		this.grid.update(time)

		const { scrollY, pointerX, pointerY } = this.app
		const x = MathUtils.clamp(((pointerX - this.left) / this.width) * 2 - 1, -1, 1)
		const y = MathUtils.clamp(((pointerY - this.top + scrollY) / this.height) * 2 - 1, 0.1, 1) * (1 - Math.abs(x))

		this.pointer.x += (x - this.pointer.x) * DELAY
		this.pointer.y += (y - this.pointer.y) * DELAY

		this.dolly.rotation.x = this.pointer.y * -0.5
		this.dolly.rotation.y = this.pointer.x * -1

		this.renderer.render(this.scene, this.camera)
	}
}
