import styles from './Button.module.css'
import clsx from 'clsx'

export function Button(text: string, href?: string, visible = false): string {
	const classes = clsx(styles.Main, visible && styles.Visible)

	return /* HTML */ `
		${href ? `<a class="${classes}" href="${href}">` : `<button class="${classes}">`}
		<span class="${styles.Wrapper}"><span class="${styles.Inner}">${text}</span></span>
		${href ? `</a>` : `</button>`}
	`
}
