import {
	Color,
	DoubleSide,
	Group,
	InstancedBufferAttribute,
	InstancedBufferGeometry,
	Mesh,
	Object3D,
	ShaderMaterial,
	Texture,
	Vector2
} from 'three'
import msdfVertexShader from './instancedMsdf.vert'
import msdfFragmentShader from './instancedMsdf.frag'
import { Stage } from '../Stage'
import BaseLayer from './BaseLayer'
import { COLORS, FONT_SCALE, LINE_HEIGHT } from '../../../const'
import { getTextGeometry } from '../textGeometry/getTextGeometry'

export class InstancedIntroText {
	private readonly object = new Group()
	private readonly inner = new Group()
	private readonly material: ShaderMaterial

	private mesh?: Mesh<InstancedBufferGeometry, ShaderMaterial>
	private geometry?: InstancedBufferGeometry
	private opacity?: InstancedBufferAttribute
	private width = 0
	private baseScale = FONT_SCALE
	private initialized = false

	constructor(
		private readonly stage: Stage,
		public readonly layer: BaseLayer | null,
		parent: Object3D,
		private readonly text: string,
		private readonly numX = 1,
		private readonly numY = 1,
		private readonly translate: InstancedBufferAttribute
	) {
		this.material = new ShaderMaterial({
			extensions: {
				derivatives: true
			},
			vertexShader: msdfVertexShader,
			fragmentShader: msdfFragmentShader,
			side: DoubleSide,
			transparent: true,
			uniforms: {
				color: { value: new Color(COLORS.grey01) },
				resolution: { value: new Vector2() },
				sdf: { value: this.stage.sdfTextures.get('regular') },
				map: { value: null },
				opacity: { value: 1 },
				offset: { value: 0 }
			}
		})

		this.object.add(this.inner)
		this.inner.renderOrder = 15

		parent.add(this.object)
	}

	async init() {
		if (this.initialized) return

		const { geometry, size } = await getTextGeometry(
			this.text,
			'regular',
			this.baseScale,
			this.stage.sdfFonts.get('regular')
		)
		this.width = size.x

		const opacity = new Float32Array(this.numX * this.numY).fill(1)

		this.opacity = new InstancedBufferAttribute(opacity, 1)
		this.geometry = new InstancedBufferGeometry()
		this.geometry.index = geometry.index
		this.geometry.setAttribute('uv', geometry.getAttribute('uv'))
		this.geometry.setAttribute('position', geometry.getAttribute('position'))
		this.geometry.setAttribute('opacity', this.opacity)
		this.geometry.setAttribute('translate', this.translate)

		this.geometry.attributes.translate.needsUpdate = true

		this.mesh = new Mesh(this.geometry, this.material)
		this.mesh.frustumCulled = false

		this.inner.add(this.mesh)
		this.initialized = true
	}

	show() {
		if (this.mesh) {
			this.mesh.visible = true
		}
	}

	setOpacity(opacity: number) {
		this.material.uniforms.opacity.value = opacity
	}

	setBaseScale(scale: number) {
		if (!this.initialized) return

		const baseScale = FONT_SCALE * scale

		this.geometry?.scale(1 / this.baseScale, 1 / this.baseScale, 1 / this.baseScale)
		this.geometry?.scale(baseScale, baseScale, baseScale)
		this.baseScale = baseScale

		if (this.mesh) {
			this.mesh.position.x = this.width * scale * -0.5
			this.mesh.position.y = LINE_HEIGHT * scale * -1
			this.inner.position.y = LINE_HEIGHT * scale * 0.675 //optical centering
		}
	}

	resize() {
		const { width, height, dpr } = this.stage
		this.material.uniforms.resolution.value.set(width * dpr, height * dpr)
	}

	dispose() {
		this.mesh?.material.dispose()
		this.mesh?.geometry.dispose()
	}

	update(time: number, map?: Texture | null) {
		if (!this.initialized) return
		if (!this.layer) return

		if (time < this.layer.in || time > this.layer.out) {
			this.object.visible = false
			return
		}

		this.object.visible = true
		const { aspect } = this.stage

		if (map) {
			this.material.uniforms.map.value = map
		}

		const { scaleX: scale, opacity } = this.layer.transform
		const slider = this.layer.slider || 0

		this.material.uniforms.opacity.value = opacity
		this.object.scale.set(scale, scale, scale)
		this.object.position.y = -0.5 / aspect

		const halfX = Math.floor(this.numX * 0.5)
		const halfY = Math.floor(this.numY * 0.5)

		for (let i = 0; i < this.numY; i++) {
			for (let ii = 0; ii < this.numX; ii++) {
				const x = ii - halfX
				const y = i - halfY
				const index = i * this.numX + ii

				const distToCenter = Math.abs(x) + Math.abs(y)
				const opacity = distToCenter > 0 ? slider : 1
				this.opacity?.setX(index, opacity)
			}
		}

		if (this.opacity) {
			this.opacity.needsUpdate = true
		}
	}
}
