import styles from './Grid.module.css'
import clsx from 'clsx'

const Cross = `<svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
<path d="M10.875 5.26153V8.25H11.625V5.26153C14.64 5.44745 17.0525 7.85999 17.2385 10.875H14.25V11.625H17.2385C17.0525 14.64 14.64 17.0525 11.625 17.2385V14.25H10.875V17.2385C7.85999 17.0525 5.44745 14.64 5.26153 11.625H8.25V10.875H5.26153C5.44745 7.85999 7.85999 5.44745 10.875 5.26153ZM10.875 17.9898C7.44564 17.802 4.69802 15.0544 4.51024 11.625H0V10.875H4.51024C4.69802 7.44564 7.44564 4.69802 10.875 4.51024V0H11.625V4.51024C15.0544 4.69802 17.802 7.44564 17.9898 10.875H22.5V11.625H17.9898C17.802 15.0544 15.0544 17.802 11.625 17.9898V22.5H10.875V17.9898ZM11.6126 14.187C12.9474 14.0179 14.0051 12.96 14.1738 11.625H11.6126V14.187ZM11.6126 8.31318C12.9474 8.48227 14.005 9.54012 14.1738 10.875H11.6126L11.6126 8.31318ZM10.8626 8.31299V10.875H8.29982C8.46866 9.5396 9.52706 8.48144 10.8626 8.31299ZM10.8626 14.1872C9.52701 14.0187 8.46858 12.9605 8.2998 11.625H10.8626V14.1872Z" />
</svg>
`

const Lines = `<svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
<path d="M1 0V30" />
<path d="M11 40L41 40" />
</svg>
`

export function Grid(): string {
	return /* HTML */ `
		<div class="${styles.Main}">
			<div class="${clsx(styles.Lines, styles.TopLeft)}">${Lines}</div>
			<div class="${clsx(styles.Lines, styles.TopRight)}">${Lines}</div>
			<div class="${clsx(styles.Lines, styles.BottomLeft)}">${Lines}</div>
			<div class="${clsx(styles.Lines, styles.BottomRight)}">${Lines}</div>
			<div class="${clsx(styles.Cross, styles.Top)}">${Cross}</div>
			<div class="${clsx(styles.Cross, styles.Bottom)}">${Cross}</div>
			<div class="${clsx(styles.Cross, styles.Left)}">${Cross}</div>
			<div class="${clsx(styles.Cross, styles.Right)}">${Cross}</div>
		</div>
	`
}
