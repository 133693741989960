import styles from './Cards.module.css'
import { CardsController } from './CardsController'
import { Easing, Tween } from '@tweenjs/tween.js'

export class CardAnimator {
	private tween1?: Tween<{ opacity: number }>
	private tween2?: Tween<{ y: number }>
	private opacity = 1

	constructor(
		private readonly node: HTMLElement,
		private readonly index: number,
		private readonly controller: CardsController
	) {}

	async setActive() {
		this.tween1?.stop()
		this.tween2?.stop()

		this.animate(true)
	}

	unsetActive() {
		this.tween1?.stop()
		this.tween2?.stop()

		this.animate(false)
	}

	show() {
		this.unsetActive()
	}

	hide() {
		this.opacity = 0
		this.node.style.setProperty('opacity', `${0}`)
	}

	animate(active: boolean) {
		this.tween2 = new Tween({ y: -1.5 }, this.controller.group)
			.to({ y: 0 }, 750)
			.delay(this.index * 150)
			.easing(Easing.Exponential.Out)
			.onStart(() => {
				this.opacity = 1
				this.node.style.setProperty('opacity', '1')
			})
			.onUpdate(({ y }) => {
				this.node.style.setProperty('transform', `translateY(${y}em)`)
			})

		this.tween1 = new Tween({ opacity: this.opacity }, this.controller.group)
			.to({ opacity: 0 }, 250)
			.onUpdate(({ opacity }) => {
				this.opacity = opacity
				this.node.style.setProperty('opacity', `${opacity}`)
			})
			.onComplete(() =>
				active ? this.node.classList.add(styles.Active) : this.node.classList.remove(styles.Active)
			)
			.chain(this.tween2)
			.start()
	}
}
