import { Mesh, PlaneGeometry, Scene, ShaderMaterial, Texture, Vector2, WebGLRenderTarget } from 'three'
import fragmentShader from './blur.frag'
import vertexShader from './blur.vert'
import { Stage } from '../Stage'

const BACKGROUND_BLUR = 0.15

export default class Blur {
	private readonly renderTarget1: WebGLRenderTarget
	private readonly renderTarget2: WebGLRenderTarget
	private readonly scene: Scene
	private readonly mesh: Mesh<PlaneGeometry, ShaderMaterial>

	constructor(private readonly stage: Stage) {
		this.renderTarget1 = new WebGLRenderTarget(1, 1)
		this.renderTarget2 = new WebGLRenderTarget(1, 1)

		this.scene = new Scene()
		this.mesh = new Mesh(
			new PlaneGeometry(1, 1),
			new ShaderMaterial({
				vertexShader,
				fragmentShader,
				uniforms: {
					map: { value: null },
					delta: { value: new Vector2(BACKGROUND_BLUR, 0) },
					resolution: { value: new Vector2() }
				}
			})
		)

		this.scene.add(this.mesh)
	}

	getTexture() {
		return this.renderTarget2.texture
	}

	resize() {
		const { width, height, hFov, vFov, dpr } = this.stage
		const w = Math.floor(width * dpr * 0.2)
		const h = Math.floor(height * dpr * 0.2)

		this.mesh.scale.set(hFov, vFov, 1)
		this.renderTarget1.setSize(w, h)
		this.renderTarget2.setSize(w, h)
		this.mesh.material.uniforms.resolution.value.set(w, h)
	}

	dispose() {
		this.mesh.material.dispose()
		this.mesh.geometry.dispose()

		this.renderTarget1.dispose()
		this.renderTarget2.dispose()

		this.scene.remove(this.mesh)
	}

	update(map: Texture | null): void {
		this.mesh.material.uniforms.map.value = map
		this.mesh.material.uniforms.delta.value.set(BACKGROUND_BLUR, 0)
		this.stage.renderer.setRenderTarget(this.renderTarget1)
		this.stage.renderer.render(this.scene, this.stage.camera)

		this.mesh.material.uniforms.map.value = this.renderTarget1.texture
		this.mesh.material.uniforms.delta.value.set(0, BACKGROUND_BLUR)
		this.stage.renderer.setRenderTarget(this.renderTarget2)
		this.stage.renderer.render(this.scene, this.stage.camera)

		this.stage.renderer.setRenderTarget(null)
	}
}
