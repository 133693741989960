import styles from './Panel.module.css'
import { App, Controller } from '../App'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import { delay } from '../../utils/delay'

export class PanelController implements Controller {
	private readonly group = new Group()
	private readonly slide: boolean
	private readonly close: HTMLElement | null
	private opacity = 0

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.onIntersect = this.onIntersect.bind(this)
		this.slide = this.node.classList.contains(styles.Slide)
		this.close = this.node.querySelector(`.${styles.Close}`)
	}

	async show(animate = false): Promise<void> {
		this.group.removeAll()

		if (animate) {
			await delay(2000)
		}

		new Tween({ y: 20 }, this.group)
			.to({ y: 0 }, 1500)
			.easing(Easing.Exponential.Out)
			.delay(500)
			.onStart(() => {
				this.node.style.setProperty('opacity', '1')
				this.node.style.setProperty('transform', 'translateY(20vh)')
			})
			.onUpdate(({ y }) => this.node.style.setProperty('transform', `translateY(${y}vh)`))
			.onComplete(() => this.node.style.removeProperty('transform'))
			.start()

		await delay(1000)
		this.close?.classList.add(styles.Visible)
	}

	async hide(): Promise<void> {
		if (!this.node) return

		return new Promise<void>((resolve) =>
			new Tween({ opacity: 1 }, this.group)
				.to({ opacity: 0 }, 500)
				.onUpdate(({ opacity }) => {
					this.opacity = opacity
					this.node.style.setProperty('opacity', `${opacity}`)
				})
				.onComplete(() => {
					this.node.style.setProperty('opacity', '0')
					resolve()
				})
				.start()
		)
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				if (entry.isIntersecting) {
					this.close?.classList.add(styles.Visible)
				}
			}
		})
	}

	update(time: number) {
		this.group.update(time)
	}

	dispose() {
		this.group.removeAll()
		this.app.removeListener('intersect', this.onIntersect)
		this.app.intersectionObserver.unobserve(this.node)
	}
}
