import * as prismic from '@prismicio/client'
import cookie from 'js-cookie'

import '../index.css'
import '../keyframes.css'

import { ResponsiveImageController } from './responsiveImage/ResponsiveImageController'
import { CardsController } from './cards/CardsController'
import { ProductsController } from './products/ProductsController'
import { NavigationController } from './navigation/NavigationController'
import { PanelController } from './panel/PanelController'
import { PageController } from './PageController'

import styles from './Html.module.css'
import introStyles from './intro/Intro.module.css'
import panelStyles from './panel/Panel.module.css'
import galleryStyles from './gallery/Gallery.module.css'
import tableStyles from './table/Table.module.css'
import machinesStyles from './machines/Machines.module.css'
import pageStyles from './Page.module.css'
import productsStyles from './products/Products.module.css'
import cardsStyles from './cards/Cards.module.css'
import navigationStyles from './navigation/Navigation.module.css'
import responsiveImageStyles from './responsiveImage/ResponsiveImage.module.css'
import anchorStyles from './anchor/Anchor.module.css'
import nodeStyles from './node/Node.module.css'

import { EventEmitter } from './EventEmitter'
import { getPreview } from './Preview'
import { Router } from './Router'
import { Stage } from './Stage'
import { repo_name } from '../../config.json'

import { getScrollbarWidth } from '../utils/getScrollbarWidth'
// @ts-ignore
import { update as ae2canvasUpdate } from '@kilokilo/ae2canvas'
import { delay } from '../utils/delay'
import { NodeController } from './node/NodeController'
import { MachinesController } from './machines/MachinesController'
import { TableController } from './table/TableController'
import { GalleryController } from './gallery/GalleryController'
import { AnchorController } from './anchor/AnchorController'
import { IntroController } from './intro/IntroController'
import { Lang } from '../lib/primsic'
import { CookieController } from './cookie/CookieController'

export interface Controller {
	dispose?(): void

	resize?(): void

	show?(animate?: boolean): Promise<void>

	hide?(): Promise<void>

	update?(time: number): void

	load?(): Promise<void>

	scroll?(scrollY: number): void
}

export class App extends EventEmitter {
	private readonly parser: DOMParser = new DOMParser()
	private readonly previewRef: string | undefined
	private readonly hasPreview: boolean
	private readonly observer: ResizeObserver
	private readonly pageController: PageController
	private readonly stage: Stage

	private controllers: Controller[] = []
	private isBodyFixed = true
	private needsScrollUpdate = false

	public readonly intersectionObserver: IntersectionObserver
	public readonly router: Router

	public pointerX = 0
	public pointerY = 0

	public bodyWidth = 0
	public bodyHeight = 0
	public windowWidth = 0
	public windowHeight = 0
	public scrollY = 0
	public isDesktop = false
	public isLandscape = false
	public lang: Lang = Lang.de

	constructor() {
		super()
		this.update = this.update.bind(this)

		const mq = window.matchMedia('(min-width:1024px)')
		mq.addEventListener('change', () => (this.isDesktop = mq.matches))
		this.isDesktop = mq.matches

		this.windowWidth = window.innerWidth
		this.windowHeight = window.innerHeight
		this.isLandscape = this.windowWidth > this.windowHeight
		this.lang = Lang[document.documentElement.lang as Lang]

		//remove hash on load
		history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`)

		const scrollbarWidth = getScrollbarWidth()
		this.isBodyFixed = document.body.classList.contains(styles.IsFixed)

		document.body.style.setProperty('--scrollbar', `${scrollbarWidth}px`)

		this.router = new Router()
		this.router.on('change', this.onNavigate.bind(this))
		this.intersectionObserver = new IntersectionObserver((entries) => this.emit('intersect', entries))

		new CookieController()
		this.pageController = new PageController()

		const previewCookie = cookie.get(prismic.cookie.preview)
		this.previewRef = previewCookie
		this.hasPreview = previewCookie ? !!JSON.parse(previewCookie)[`${repo_name}.prismic.io`] : false

		this.observer = new ResizeObserver(this.onResize.bind(this))

		const stage = document.querySelector(`.${styles.Stage}`) as HTMLElement
		this.stage = new Stage(stage, this)

		this.init()
	}

	async onFontLoad() {
		return new Promise<void>((resolve) => document.fonts.ready.then(() => resolve()))
	}

	async init(): Promise<void> {
		await Promise.all([this.onFontLoad()])

		if (this.hasPreview) {
			const html = await this.loadPage(window.location.pathname)
			if (html) this.updatePage(html)
		}

		requestAnimationFrame(this.update)
		document.body.addEventListener('click', this.onDocumentClick.bind(this))
		document.body.addEventListener('pointermove', this.onPointerMove.bind(this))
		window.addEventListener('scroll', this.onScroll.bind(this))

		const { intro, fluidTitles } = this.pageController

		await this.stage.load(fluidTitles, intro) //stage must be loaded first

		this.setControllers(true, !!intro)

		await Promise.all([this.loadControllers(), delay(500)])
		//TODO remove delay?

		this.observer.observe(document.body)

		this.controllers.forEach((controller) => controller.show?.(true))
		await this.stage.show(true)

		this.unsetBodyFixed()
	}

	onPointerMove(event: PointerEvent) {
		this.pointerX = event.x
		this.pointerY = event.y
	}

	onDocumentClick(event: MouseEvent): void {
		let target: HTMLElement = event.target as HTMLElement
		let anchorClick = false

		while (target && target.parentNode) {
			if (target.tagName === 'A') {
				const { origin, pathname, hash } = new URL((target as HTMLAnchorElement).href)
				if (origin === window.location.origin && !(hash && pathname === window.location.pathname)) {
					event.preventDefault()
					this.router.push(pathname)
				}
				anchorClick = true
				break
			}
			target = target.parentNode as HTMLElement
		}

		if (!anchorClick) {
			this.stage?.onClick()
		}
	}

	async onNavigate(route: string): Promise<void> {
		this.setBodyFixed()

		const [html] = await Promise.all([
			this.loadPage(route),
			this.stage?.hide(),
			...this.controllers.map((controller) => controller.hide?.()).filter(Boolean)
		])

		this.controllers.forEach((controller) => controller.dispose?.())

		this.updatePage(html)

		const { intro, fluidTitles } = this.pageController

		this.setControllers(false, !!intro)

		await Promise.all([
			...this.controllers.map((controller) => controller.load?.()),
			this.stage?.load(fluidTitles)
		])

		this.onResize()

		this.controllers.forEach((controller) => controller.show?.(false))
		this.stage?.show(false, !!intro)

		this.unsetBodyFixed()
	}

	async loadPage(route?: string): Promise<string> {
		if (this.hasPreview && this.previewRef) {
			return await getPreview(this.previewRef, route)
		} else {
			const response = await fetch(`${route}index.html`)
			return await response.text()
		}
	}

	updatePage(html: string): void {
		const doc = this.parser.parseFromString(html, 'text/html')
		const mainNode = doc.querySelector(`.${pageStyles.Main}`) as HTMLElement

		document.title = doc.title
		document.documentElement.lang = doc.documentElement.lang
		this.lang = Lang[doc.documentElement.lang as Lang]

		window.scrollTo(0, 0)
		this.scrollY = 0
		this.pageController.updateDOM(mainNode)
	}

	setControllers(animate = false, intro = false): void {
		this.controllers = [
			...this.getNodes(introStyles.Main).map((node) => new IntroController(node, this, this.stage)),
			...this.getNodes(responsiveImageStyles.Main).map((node) => new ResponsiveImageController(node)),
			...this.getNodes(navigationStyles.Main).map((node) => new NavigationController(node, this, animate, intro)),
			...this.getNodes(productsStyles.Main).map((node) => new ProductsController(node, this)),
			...this.getNodes(cardsStyles.Main).map((node) => new CardsController(node, this)),
			...this.getNodes(panelStyles.Main).map((node) => new PanelController(node, this)),
			...this.getNodes(machinesStyles.Main).map((node) => new MachinesController(node, this)),
			...this.getNodes(tableStyles.Main).map((node) => new TableController(node, this)),
			...this.getNodes(galleryStyles.Main).map((node) => new GalleryController(node, this)),
			...this.getNodes(anchorStyles.Main).map((node) => new AnchorController(node)),
			...this.getNodes(nodeStyles.Main).map((node) => new NodeController(node, this))
		]
	}

	async loadControllers() {
		await Promise.all([this.controllers.map((controller) => controller.load?.())])
	}

	getNodes(className: string): HTMLElement[] {
		return Array.from(document.body?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
	}

	onHover(hover: null | string | number) {
		hover === null
			? document.body.style.removeProperty('cursor')
			: document.body.style.setProperty('cursor', 'pointer')
	}

	setBodyFixed(): void {
		if (this.isBodyFixed) return
		this.isBodyFixed = true
		this.scrollY = window.scrollY
		this.pageController.setFixed(this.scrollY)
		document.body.classList.add(styles.IsFixed)
	}

	unsetBodyFixed(): void {
		if (!this.isBodyFixed) return
		this.isBodyFixed = false
		this.pageController.unsetFixed()
		document.body.classList.remove(styles.IsFixed)
		window.scrollTo(0, this.scrollY)
	}

	onResize(): void {
		const { width, height } = this.pageController.getBoundingClientRect() || { width: 0, height: 0 }

		// prevent resize events on scroll
		if (this.bodyWidth !== width || this.bodyHeight !== height) {
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
			this.bodyWidth = width
			this.bodyHeight = height
			this.isLandscape = this.windowWidth > this.windowHeight
			this.controllers.forEach((controller) => controller.resize?.())
		}
	}

	onScroll(): void {
		this.needsScrollUpdate = true
	}

	update(time: number): void {
		requestAnimationFrame(this.update)
		ae2canvasUpdate(time)

		if (this.needsScrollUpdate && !this.isBodyFixed) {
			this.needsScrollUpdate = false
			this.scrollY = window.scrollY
			this.controllers.forEach((controller) => controller.scroll?.(window.scrollY))
		}

		this.pageController.update(time)
		this.stage?.update(time)
		this.controllers.forEach((controller) => controller.update?.(time))
	}
}
