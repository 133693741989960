import styles from './Title.module.css'
import nodeStyles from '../node/Node.module.css'
import typoStyles from '../Typography.module.css'
import { PageDocumentDataBodyTitleSlice } from '../../@types/types.generated'
import clsx from 'clsx'

export const Title = (props: PageDocumentDataBodyTitleSlice): string => {
	const {
		primary: { title, subtitle, background }
	} = props
	return /* HTML */ `<div
		class="${clsx(
			styles.Main,
			background === 'grey 02' && styles.IsGrey02,
			background === 'white' && styles.IsWhite,
			nodeStyles.Main
		)}"
		data-visible="${styles.Visible}"
	>
		${title ? `<h2 class="${clsx(typoStyles.Title, styles.Title)}"><span>${title}</span></h2>` : ''}
		${subtitle ? `<div class="${clsx(typoStyles.Subtitle, styles.Subtitle)}"><span>${subtitle}</span></div>` : ''}
	</div>`
}
