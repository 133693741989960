export const ASPECT_POSTER = 8 / 6

export const FONT_SCALE = 1 / 1600

export const LINE_HEIGHT = 1 / 14
export const SPACE_WIDTH = 1 / 66.667

export enum COLORS {
	grey01 = '#e7e7e7',
	grey02 = '#dfdfdf',
	grey03 = '#c4c4c4',
	grey04 = '#777777',
	grey05 = '#5c5c5c',
	grey06 = '#272727',
	black = '#000000',
	white = '#ffffff'
}
