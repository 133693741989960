import styles from './Page.module.css'
import { NavigationDocument, PageDocument, PageDocumentDataBodyIntroSlice } from '../@types/types.generated'
import { Lang } from '../lib/primsic'
import { Navigation } from './navigation/Navigation'
import { Intro } from './intro/Intro'
import { FluidTitle } from './fluidTitle/FluidTitle'
import { Panel } from './panel/Panel'
import { Spacer } from './Spacer'
import { CustomSlice } from '../utils/prepareSlices'

type Props = {
	page: PageDocument<Lang>
	route: string
	routes: string[]
	navigation?: NavigationDocument<Lang>
	slices: CustomSlice[]
}

export function Page({ page, navigation, route, slices }: Props): string {
	const { alternate_languages, lang, data: { page_title, close_button, body = [] } = {} } = page
	const intro = body.find((slice) => slice.slice_type === 'intro') as PageDocumentDataBodyIntroSlice
	let introText = ''
	if (intro) {
		const {
			primary: { text_1, text_2, text_3, text_4, text_5, text_6, text_7 }
		} = intro

		introText = encodeURI(JSON.stringify([text_1, text_2, text_3, text_4, text_5, text_6, text_7]))
	}

	return /* HTML */ `
		<main class="${styles.Main}" data-intro="${introText}">
			${navigation ? Navigation({ navigation, route, lang, alternate_languages }) : ''}
			${body.length > 0
				? /* HTML */ `<div class="${styles.Inner}">
						${page_title ? `<h1 class="${styles.Title}">${page_title?.toLocaleLowerCase()}</h1>` : ''}
						${slices
							.map((slice, index) => {
								switch (slice.slice_type) {
									case 'fluid_title':
										return FluidTitle(slice)
									case 'intro':
										return Intro(slice)
									case 'spacer':
										return Spacer(slice)
									case 'panel':
										return Panel({
											...slice,
											lang,
											slide: index === 1,
											closeButton: !!close_button
										})
								}
							})
							.join('')}
				  </div>`
				: ''}
		</main>
	`
}
