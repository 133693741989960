import Property from './Property'
import AnimatedProperty from './AnimatedProperty'
import Position from './Position'

export type TransformProps = {
	position?: []
	positionX?: []
	positionY?: []
	scaleX?: []
	scaleY?: []
	rotation?: []
	opacity?: []
}

class Transform {
	private readonly positionProperty?: Property<[number, number]>
	private readonly positionXProperty?: Property<number>
	public readonly positionYProperty?: Property<number>
	private readonly scaleXProperty?: Property<number>
	private readonly scaleYProperty?: Property<number>
	private readonly rotationProperty?: Property<number>
	private readonly opacityProperty?: Property<number>

	public positionX = 0
	public positionY = 0
	public rotation = 0
	public scaleX = 1
	public scaleY = 1
	public opacity = 1

	constructor(data: TransformProps) {
		if (data.position) {
			if (data.position.length > 1) {
				this.positionProperty = new Position(data.position)
			} else {
				this.positionProperty = new Property(data.position)
			}
		}

		if (data.positionX) {
			this.positionXProperty =
				data.positionX.length > 1 ? new AnimatedProperty(data.positionX) : new Property(data.positionX)
		}
		if (data.positionY) {
			this.positionYProperty =
				data.positionY.length > 1 ? new AnimatedProperty(data.positionY) : new Property(data.positionY)
		}
		if (data.scaleX) {
			this.scaleXProperty = data.scaleX.length > 1 ? new AnimatedProperty(data.scaleX) : new Property(data.scaleX)
		}
		if (data.scaleY) {
			this.scaleYProperty = data.scaleY.length > 1 ? new AnimatedProperty(data.scaleY) : new Property(data.scaleY)
		}
		if (data.rotation) {
			this.rotationProperty =
				data.rotation.length > 1 ? new AnimatedProperty(data.rotation) : new Property(data.rotation)
		}
		if (data.opacity) {
			this.opacityProperty =
				data.opacity.length > 1 ? new AnimatedProperty(data.opacity) : new Property(data.opacity)
		}
	}

	update(time: number) {
		let positionX
		let positionY

		const rotation = this.rotationProperty ? this.deg2rad(this.rotationProperty.getValue(time)) : 0
		const scaleX = this.scaleXProperty ? this.scaleXProperty.getValue(time) : 1
		const scaleY = this.scaleYProperty ? this.scaleYProperty.getValue(time) : 1
		const opacity = this.opacityProperty ? this.opacityProperty.getValue(time) : 1

		if (this.positionProperty) {
			const position = this.positionProperty.getValue(time)
			positionX = position[0]
			positionY = position[1]
		} else {
			positionX = this.positionXProperty ? this.positionXProperty.getValue(time) : 0
			positionY = this.positionYProperty ? this.positionYProperty.getValue(time) : 0
		}

		this.positionX = positionX
		this.positionY = positionY
		this.rotation = rotation
		this.scaleX = scaleX
		this.scaleY = scaleY
		this.opacity = opacity
	}

	deg2rad(deg: number) {
		return deg * (Math.PI / 180)
	}

	setKeyframes(time: number) {
		if (this.rotationProperty) this.rotationProperty.setKeyframes(time)
		if (this.positionProperty) this.positionProperty.setKeyframes(time)
		if (this.positionXProperty) this.positionXProperty.setKeyframes(time)
		if (this.positionYProperty) this.positionYProperty.setKeyframes(time)
		if (this.scaleXProperty) this.scaleXProperty.setKeyframes(time)
		if (this.scaleYProperty) this.scaleYProperty.setKeyframes(time)
		if (this.opacityProperty) this.opacityProperty.setKeyframes(time)
	}

	reset() {
		if (this.rotationProperty) this.rotationProperty.reset()
		if (this.positionProperty) this.positionProperty.reset()
		if (this.positionXProperty) this.positionXProperty.reset()
		if (this.positionYProperty) this.positionYProperty.reset()
		if (this.scaleXProperty) this.scaleXProperty.reset()
		if (this.scaleYProperty) this.scaleYProperty.reset()
		if (this.opacityProperty) this.opacityProperty.reset()
	}
}

export default Transform
