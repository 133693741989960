import styles from './Columns.module.css'
import { PageDocumentDataBodyColumnsSlice } from '../@types/types.generated'
import typoStyles from './Typography.module.css'
import { asHTML, asLink, Element, HTMLFunctionSerializer } from '@prismicio/helpers'
import linkResolver from '../lib/linkResolver'
import { FilledLinkToWebField, LinkType, RTAnyNode, RTLinkNode } from '@prismicio/types'
import { Arrow } from './Arrow'

const Icon = (flipped?: boolean) => `<span class="${styles.Icon}"><span>${Arrow(flipped)}</span></span>`

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text: string | undefined
): string | null | undefined => {
	if (type === Element.hyperlink) {
		const { data } = node as RTLinkNode

		switch (data.link_type) {
			case LinkType.Web: {
				const { url, target } = data as FilledLinkToWebField
				return /* HTML */ `<a
					class="${styles.Link}"
					href="${url}"
					${target ? `target="${target}"` : ''}
					rel="noopener noreferrer"
					><span>${text}</span>${Icon()}</a
				>`
			}

			case LinkType.Document: {
				return /* HTML */ `<a class="${styles.Link}" href="${asLink(data, linkResolver)}"
					><span>${text}</span>${Icon()}</a
				>`
			}

			case LinkType.Media: {
				const { url } = data
				return /* HTML */ `<a class="${styles.Link}" href="${url}"><span>${text}</span>${Icon()}</a>`
			}
		}
	}

	return null
}

export const Columns = (props: PageDocumentDataBodyColumnsSlice): string => {
	const { items } = props
	return /* HTML */ `<div class="${styles.Main}">
		${items
			.map(
				({ text }) => /* HTML */ `<div class="${styles.Column}">
					<div class="${typoStyles.Text}">${asHTML(text, linkResolver, serializer)}</div>
				</div>`
			)
			.join('')}
	</div>`
}
